import React, { Component } from "react";
import { Form, Input, Button, Col, Row, Popconfirm } from "antd";
import { connect } from "react-redux";
import Dragger from "antd/lib/upload/Dragger";
import { InboxOutlined } from "@ant-design/icons";
import { modifyProfileUser, deleteProfilePic } from "../../actions/users";
import { refreshUser } from "../../actions/login";
import HttpService from "../../services/http";

const formItemLayout = {
  labelCol: {
    xs: {
      span: 4,
    },
    sm: {
      span: 2,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 12,
    },
  },
};

const mapDispatchToProps = (dispatch) => {
  return {
    modifyProfileUser: (formData) => dispatch(modifyProfileUser(formData)),
    refreshUser: (userId) => dispatch(refreshUser(userId)),
    deleteProfilePicture: (userId) => dispatch(deleteProfilePic(userId)),
  };
};

class ConnectedProfile extends Component {
  formRef = React.createRef();

  state = {
    email: "",
    password: "",
    firstname: "",
    lastname: "",
    file: [],
  };

  componentDidMount = () => {
    const { user } = this.props;
    this.setState({
      firstname: user.firstname,
      lastname: user.lastname,
      email: user.email,
    });

    this.formRef.current.setFieldsValue({
      firstname: user.firstname,
      lastname: user.lastname,
      email: user.email,
    });
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  removeFile = () => {
    this.setState({ file: [] });
  };

  beforeUpload = (file) => {
    this.setState({ file: [file] });
    return false;
  };

  refreshFormAndUser = async () => {
    await this.props.refreshUser(this.props.user.id);
    const { user } = this.props;
    this.setState({
      firstname: user.firstname,
      lastname: user.lastname,
      email: user.email,
      password: "",
      file: [],
    });
    HttpService.setAxiosDefaults(this.props.token);
    this.formRef.current.resetFields();
    this.formRef.current.setFieldsValue({
      firstname: user.firstname,
      lastname: user.lastname,
      email: user.email,
    });
  };

  submitForm = async () => {
    const formData = new FormData();
    formData.set("id", this.props.user.id);
    formData.set("email", this.state.email);
    formData.set("firstname", this.state.firstname);
    formData.set("lastname", this.state.lastname);
    formData.set("password", this.state.password);
    if (this.state.file.length > 0) {
      formData.append("file", this.state.file[0]);
    }
    await this.props.modifyProfileUser(formData);
    if (this.props.saveStatus) {
      await this.refreshFormAndUser();
    }
  };

  handleClickDeletePicture = async () => {
    await this.props.deleteProfilePicture(this.props.user.id);
    await this.refreshFormAndUser();
  };

  render() {
    return (
      <div>
        <Row>
          <Col span={18} style={{ padding: "1em" }}>
            <h1>Benutzerprofil</h1>
            <Form
              ref={this.formRef}
              {...formItemLayout}
              name="register"
              onFinish={this.submitForm}
              scrollToFirstError
            >
              <Form.Item
                name="email"
                label="E-mail"
                rules={[
                  {
                    type: "email",
                    message: "Die Eingabe ist ungültig Email!",
                  },
                  {
                    required: true,
                    message: "Bitte geben Sie Ihre E-Mail Adresse ein!",
                  },
                ]}
              >
                <Input
                  name="email"
                  value={this.state.email}
                  onChange={this.handleChange}
                />
              </Form.Item>
              <Form.Item
                name="password"
                label="Passwort"
                rules={[
                  {
                    required: false,
                    message: "Bitte geben Sie Ihr Passwort ein!",
                  },
                ]}
                hasFeedback
              >
                <Input.Password
                  name="password"
                  value={this.state.password}
                  onChange={this.handleChange}
                />
              </Form.Item>
              <Form.Item
                name="firstname"
                label="Vorname"
                rules={[
                  {
                    required: true,
                    message: "Bitte geben Sie Ihre Vorname ein!",
                    whitespace: true,
                  },
                ]}
              >
                <Input
                  name="firstname"
                  value={this.state.firstname}
                  onChange={this.handleChange}
                />
              </Form.Item>
              <Form.Item
                name="lastname"
                label="Nachname"
                rules={[
                  {
                    required: true,
                    message: "Bitte geben Sie Ihre Nachname ein!",
                    whitespace: true,
                  },
                ]}
              >
                <Input
                  name="lastname"
                  value={this.state.lastname}
                  onChange={this.handleChange}
                />
              </Form.Item>
              <Form.Item
                name="file"
                label="Profilbild"
                rules={[
                  {
                    required: false,
                    message: "Bitte geben Sie den Datei ein!",
                  },
                ]}
              >
                <Dragger
                  name="file"
                  multiple={false}
                  //onChange={this.changeUploadedFile}
                  onRemove={this.removeFile}
                  accept=".png,.jpg,.jpeg,.gif"
                  beforeUpload={this.beforeUpload}
                  fileList={this.state.file}
                >
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">
                    Klicken oder ziehen Sie eine Datei in diesen Bereich, um sie
                    hochzuladen
                  </p>
                </Dragger>
              </Form.Item>
              <Form.Item>
                <Button htmlType="submit" type="primary">
                  Speichern
                </Button>
              </Form.Item>
            </Form>
          </Col>
          {this.props.user.filename !== null && (
            <Col span={6} style={{ padding: "1em" }}>
              <h1>Profilbild</h1>
              <img
                src={
                  process.env.REACT_APP_BASE_URL +
                  "/1.0.0/users/profile/" +
                  this.props.user.filename +
                  "?token=" +
                  this.props.token.split(" ")[1]
                }
                alt="logo"
                width="100%"
              />
              <div style={{ padding: "0.5em" }}>
                <Popconfirm
                  title="Sind Sie sicher, dieses Profilbild zu löschen?"
                  onConfirm={this.handleClickDeletePicture}
                  okText="Ja"
                  cancelText="Nein"
                >
                  <Button>Löschen</Button>
                </Popconfirm>
              </div>
            </Col>
          )}
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  token: state.loginReducer.token,
  user: state.loginReducer.user,
  saveStatus: state.usersReducer.saveStatus,
});

const Profile = connect(mapStateToProps, mapDispatchToProps)(ConnectedProfile);
export default Profile;
