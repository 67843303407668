/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
/* import { NavLink } from "react-router-dom"; */
import {
  Button,
  Row,
  Col,
  Table,
  Space,
  Input,
  Tooltip,
  Popconfirm,
  Modal,
  Upload,
  Form,
  Select,
  message,
} from "antd";
import FormEditor from "./form-editor";
import { connect } from "react-redux";
import { getAllForms, deleteForm, activateForm } from "../../actions/forms";
import Highlighter from "react-highlight-words";
import {
  SearchOutlined,
  /* CaretLeftFilled,*/
  PlusCircleFilled,
  UploadOutlined,
} from "@ant-design/icons";
import Axios from "axios";

const mapDispatchToProps = (dispatch) => {
  return {
    getAllForms: () => dispatch(getAllForms()),
    deleteForm: (formId) => dispatch(deleteForm(formId)),
    activateForm: (formId) => dispatch(activateForm(formId)),
  };
};

class ConnectedForms extends Component {
  state = {
    visible: false,
    forms: [],
    loaded: false,
    modify: false,
    modifyExport: false,
    fileList: [],
  };

  componentDidMount = async () => {
    await this.getAllForms();
  };

  getAllForms = async () => {
    this.setState({ loaded: false });
    await this.props.getAllForms();
    if (this.props.status) {
      const forms = this.props.forms.map((form) => {
        return {
          key: form.id,
          ...form,
        };
      });
      this.setState({ loaded: true, forms: forms });
    }
  };

  openFormEditor = () => {
    this.setState({ visible: true });
  };

  handleCancel = () => {
    this.setState({ visible: false, modify: false, selectedForm: {} });
  };

  handleSave = async () => {
    this.setState({ visible: false });
    await this.getAllForms();
  };

  handleDeleteForm = async (form) => {
    await this.props.deleteForm(form.id);
    await this.getAllForms();
  };

  handleActivateForm = async (form) => {
    await this.props.activateForm(form.id);
    await this.getAllForms();
  };

  getColumnSearchProps = (dataIndex, searchLabel) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Suche ${searchLabel}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Suche
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 100 }}
          >
            Zurücksetzen
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text, record) =>
      this.state.searchedColumn === dataIndex ? (
        <Space align="center">
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[this.state.searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        </Space>
      ) : (
        <Space align="center">{text}</Space>
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  handleModifyForm = (record) => {
    this.setState({ modify: true, selectedForm: record });
    this.openFormEditor();
  };

  handleModifyFormExport = (record) => {
    this.setState({ modifyExport: true, selectedForm: record });
  };

  closeModifyExport = () => {
    this.setState({ modifyExport: false, selectedForm: null, fileList: [] });
  };

  removeFile = () => {
    this.setState({ fileList: [] });
  };

  beforeUpload = (file) => {
    this.setState({ fileList: [file] });
    return false;
  };

  handleChangeStartCell = (event, index) => {
    const selectedItem = this.state.selectedForm.items[index];
    selectedItem.exportStart = event.target.value;
    const selectedForm = this.state.selectedForm;
    selectedForm.items[index] = selectedItem;
    this.setState({ selectedForm: selectedForm });
  };

  handleChangeExportDirection = (event, index) => {
    const selectedItem = this.state.selectedForm.items[index];
    selectedItem.exportDirection = event;
    const selectedForm = this.state.selectedForm;
    selectedForm.items[index] = selectedItem;
    this.setState({ selectedForm: selectedForm });
  };

  submitExport = async () => {
    const formData = new FormData();
    formData.set("data", JSON.stringify(this.state.selectedForm));
    if (this.state.fileList.length > 0) {
      formData.append("file", this.state.fileList[0]);
    }

    await Axios.post("/1.0.0/sheets/modify-export-sheet", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then(() => {
        message.success("Erfolgreich speichern!");
        this.closeModifyExport();
      })
      .catch((err) => {
        message.error("Speichern erfolglos!");
      });
  };

  render() {
    const formItemLayout = {
      labelCol: {
        xs: {
          span: 24,
        },
        sm: {
          span: 14,
        },
      },
      wrapperCol: {
        xs: {
          span: 24,
        },
        sm: {
          span: 24,
        },
      },
    };

    const columns = [
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        ...this.getColumnSearchProps("name", "name"),
      },
      { title: "Beschreibung", dataIndex: "description", key: "description" },
      {
        title: "Aktivieren",
        key: "modify",
        render: (text, record) => (
          <Space size="middle">
            <Button onClick={() => this.handleActivateForm(record)}>
              {record.enabled === true ? "Deaktivieren" : "Aktivieren"}
            </Button>
          </Space>
        ),
      },
      {
        title: "Export Ändern",
        key: "modify",
        render: (text, record) => (
          <Space size="middle">
            <Button onClick={() => this.handleModifyFormExport(record)}>
              Export Ändern
            </Button>
          </Space>
        ),
      },
      {
        title: "Ändern",
        key: "modify",
        render: (text, record) => (
          <Space size="middle">
            <Button onClick={() => this.handleModifyForm(record)}>
              Ändern
            </Button>
          </Space>
        ),
      },
      {
        title: "Löschen",
        key: "delete",
        render: (text, record) => (
          <Space size="middle">
            <Tooltip
              placement="right"
              title={"Es gibt gefüllte Rapporte"}
              trigger={record.reports.length > 0 ? "hover" : ""}
            >
              <Popconfirm
                title="Sind Sie sicher, dieses Formular zu löschen?"
                onConfirm={() => this.handleDeleteForm(record)}
                okText="Ja"
                cancelText="Nein"
                disabled={record.reports.length > 0}
              >
                <Button
                  //onClick={() => this.handleDeleteForm(record)}
                  disabled={record.reports.length > 0}
                >
                  Löschen
                </Button>
              </Popconfirm>
            </Tooltip>
          </Space>
        ),
      },
    ];
    return (
      <div>
        <Row>
          <Col span={24} style={{ padding: "1em" }}>
            {/* <NavLink to="/fs/projekte">
              <Button type="primary" style={{ margin: "0.5em" }}>
                <CaretLeftFilled />
                Zurück
              </Button>
            </NavLink> */}
            <Button onClick={this.openFormEditor} style={{ margin: "0.5em" }}>
              <PlusCircleFilled />
              Neues Formular
            </Button>
          </Col>
        </Row>
        <Row>
          <Col span={24} style={{ padding: "1em" }}>
            <Table
              columns={columns}
              dataSource={this.state.forms}
              locale={{ emptyText: "Keine Daten" }}
              loading={!this.props.status && !this.state.loaded}
              pagination={{
                position: ["bottomCenter"],
                pageSize: 10,
              }}
            />
          </Col>
        </Row>
        <div>
          {this.state.visible && (
            <FormEditor
              projectId={this.state.projectId}
              visible={this.state.visible}
              modify={this.state.modify}
              selectedForm={this.state.selectedForm}
              cancel={this.handleCancel}
              save={this.handleSave}
            />
          )}
        </div>
        <Modal
          visible={this.state.modifyExport}
          title="Export Ändern"
          centered
          onCancel={this.closeModifyExport}
          footer={null}
        >
          <Row>
            <Form
              ref={this.formRef}
              {...formItemLayout}
              name="export"
              scrollToFirstError
              onFinish={this.submitExport}
            >
              <Form.Item label="Export file">
                <Upload
                  fileList={this.state.fileList}
                  multiple={false}
                  //onChange={this.changeUploadedFile}
                  onRemove={this.removeFile}
                  accept=".xls,.xlsx"
                  beforeUpload={this.beforeUpload}
                >
                  <Button icon={<UploadOutlined />}>Hochladen</Button>
                </Upload>
              </Form.Item>
              {this.state.selectedForm?.items.map((i, index) => {
                return (
                  <>
                    <Col offset={12}>
                      <label>
                        <strong>{i.itemLabel}</strong>
                      </label>
                    </Col>
                    <Form.Item label="Export Start Zelle">
                      <Input
                        value={i.exportStart}
                        onChange={(event) =>
                          this.handleChangeStartCell(event, index)
                        }
                      />
                    </Form.Item>
                    <Form.Item
                      label="Export Richtung"
                      //initialValue={i.exportDirection}
                    >
                      <Select
                        style={{ width: "100%", margin: "0.5em" }}
                        value={parseInt(i.exportDirection)}
                        placeholder="Richtung"
                        onChange={(event) =>
                          this.handleChangeExportDirection(event, index)
                        }
                      >
                        <Select.Option value={1}>Vertikal</Select.Option>
                        <Select.Option value={2}>Horizontal</Select.Option>
                      </Select>
                    </Form.Item>
                  </>
                );
              })}
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Speichern
                </Button>
              </Form.Item>
            </Form>
          </Row>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  forms: state.formsReducer.forms,
  status: state.formsReducer.status,
  saveStatus: state.formsReducer.saveStatus,
});

const Forms = connect(mapStateToProps, mapDispatchToProps)(ConnectedForms);
export default Forms;
