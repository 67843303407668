/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { connect } from "react-redux";
import { getAllReports } from "../../actions/reports";
import {
  Space,
  Button,
  Input,
  Row,
  Col,
  Table,
  Modal,
  message,
  DatePicker,
  Select,
  Form,
} from "antd";
import Highlighter from "react-highlight-words";
import {
  SearchOutlined,
  CaretLeftFilled,
  FileExcelFilled,
} from "@ant-design/icons";
import { NavLink } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { getAllForms } from "../../actions/forms";
import { getAllProjects } from "../../actions/project";
import * as path from "path";

const { RangePicker } = DatePicker;

const mapDispatchToProps = (dispatch) => {
  return {
    getAllReports: (projectId, userId, companyId) =>
      dispatch(getAllReports(projectId, userId, companyId)),
    getAllForms: () => dispatch(getAllForms()),
    getAllProjects: (userId, companyId) =>
      dispatch(getAllProjects(userId, companyId)),
  };
};

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};

class ConnectedReports extends Component {
  formRef = React.createRef();

  state = {
    reports: [],
    loaded: false,
    selectedReport: [],
    visible: false,
    sessionIds: [],
    start: new Date().toLocaleDateString(),
    end: new Date().toLocaleDateString(),
    exportVisible: false,
    from: new Date(),
    to: new Date(),
    selectedForm: null,
    selectedProject: -1,
  };

  componentDidMount = async () => {
    /* this.setState({ loaded: false });
    if (this.props.match.params.id) {
      await this.props.getAllReports(this.props.match.params.id);
    } else {
      if (this.props.user.groupId === 1) {
        await this.props.getAllReports();
        await this.props.getAllProjects();
      } else {
        await this.props.getAllReports(
          undefined,
          undefined,
          this.props.user.companyId
        );
        await this.props.getAllProjects(undefined, this.props.user.companyId);
      }
    }
    if (this.props.status) {
      const reports = this.props.reports.map((report) => {
        const date = new Date(report.created);
        return {
          key: report.sessionId,
          fullName:
            report.firstname === null && report.lastname === null
              ? "Kein Benutzer"
              : `${report.firstname} ${report.lastname}`,
          date: date.toLocaleDateString(),
          ...report,
        };
      });
      this.setState({ reports: reports, loaded: true });
    } */
    await this.getAllReports();
    await this.props.getAllForms();
    const start = new Date();
    start.setMonth(0, 1);
    const end = new Date();
    end.setMonth(11, 31);
    this.setState({
      start: start.toLocaleDateString(),
      end: end.toLocaleDateString(),
    });
  };

  getAllReports = async () => {
    this.setState({ loaded: false });
    if (this.props.match.params.id) {
      await this.props.getAllReports(this.props.match.params.id);
    } else {
      if (this.props.user.groupId === 1) {
        await this.props.getAllReports();
        await this.props.getAllProjects();
      } else {
        await this.props.getAllReports(
          undefined,
          undefined,
          this.props.user.companyId
        );
        await this.props.getAllProjects(undefined, this.props.user.companyId);
      }
    }
    if (this.props.status) {
      const reports = this.props.reports.map((report) => {
        const date = new Date(report.created);
        return {
          key: report.sessionId,
          fullName:
            report.firstname === null && report.lastname === null
              ? "Kein Benutzer"
              : `${report.firstname} ${report.lastname}`,
          date: date.toLocaleDateString(),
          ...report,
        };
      });
      this.setState({ reports: reports, loaded: true });
    }
  };

  onClickRow = async (sessionId) => {
    this.setState({ visible: true });
    await axios
      .get("/1.0.0/report/sessionId", {
        params: { sessionId: sessionId },
      })
      .then((response) => {
        this.setState({ selectedReport: response.data.report });
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  onCloseModal = () => {
    this.setState({ visible: false, selectedReport: [] });
  };

  handleCloseModal = () => {
    this.setState({
      exportVisible: false,
      from: new Date(),
      to: new Date(),
      selectedForm: null,
      selectedProject: -1,
    });
    this.formRef.current.resetFields();
    this.formRef.current.setFieldsValue({ project: -1 });
  };

  handleOpenModal = () => {
    this.setState({ exportVisible: true });
  };

  getColumnSearchProps = (dataIndex, searchLabel) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Suche ${searchLabel}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Suche
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 100 }}
          >
            Zurücksetzen
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text, record) =>
      this.state.searchedColumn === dataIndex ? (
        <Space align="center">
          {dataIndex === "name" ? (
            <a onClick={() => this.onClickRow(record.sessionId)}>
              <Highlighter
                highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
                searchWords={[this.state.searchText]}
                autoEscape
                textToHighlight={text.toString()}
              />
            </a>
          ) : (
            <Highlighter
              highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
              searchWords={[this.state.searchText]}
              autoEscape
              textToHighlight={text.toString()}
            />
          )}
        </Space>
      ) : (
        <Space align="center">
          {dataIndex === "name" ? (
            <a onClick={() => this.onClickRow(record.sessionId)}>{text}</a>
          ) : (
            text
          )}
        </Space>
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  handleChangeTable = (pagination, filters, sorter, extra) => {
    if (extra.currentDataSource.length === this.state.reports.length) {
      this.setState({ sessionIds: [] });
    } else {
      const sessionIds = extra.currentDataSource.map((data) => {
        return data.sessionId;
      });
      this.setState({ sessionIds: sessionIds });
    }
    this.setState({
      sortedInfo: sorter,
    });
  };

  exportCSV = async () => {
    await axios
      .get("/1.0.0/report/csv", {
        responseType: "arraybuffer",
        params: { sessionIds: this.state.sessionIds },
      })
      .then((response) => {
        const blob = new Blob([response.data]);

        let link = document.createElement("a");
        let url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute(
          "download",
          `reports-export_${new Date().toISOString()}.zip`
        );
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((err) => {
        message.error("Fehler während der CSV-Generierung");
      });
  };

  exportSpecial = async () => {
    await axios
      .get("/1.0.0/report/special", {
        responseType: "arraybuffer",
        params: { sessionIds: this.state.sessionIds },
      })
      .then((response) => {
        const blob = new Blob([response.data]);

        let link = document.createElement("a");
        let url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", `reports.zip`);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((err) => {
        message.error("Fehler während der Export Brandabschottungen");
      });
  };

  handleChangeRange = (momentArray, stringArray) => {
    const reports = this.props.reports.map((report) => {
      const date = new Date(report.created);
      return {
        key: report.sessionId,
        fullName:
          report.firstname === null && report.lastname === null
            ? "Kein Benutzer"
            : `${report.firstname} ${report.lastname}`,
        date: date.toLocaleDateString(),
        ...report,
      };
    });
    if (stringArray[0] === "" && stringArray[1] === "") {
      const start = new Date();
      start.setMonth(0, 1);
      const end = new Date();
      end.setMonth(11, 31);
      const filteredReports = reports.filter(
        (report) =>
          new Date(report.created).getTime() >= start.getTime() &&
          new Date(report.created).getTime() <= end.getTime()
      );
      this.setState({
        reports: filteredReports,
        loaded: true,
        start: start.toLocaleDateString(),
        end: end.toLocaleDateString(),
      });
    } else {
      const filteredReports = reports.filter((report) => {
        return (
          new Date(report.created).getTime() >=
            new Date(stringArray[0]).getTime() &&
          new Date(report.created).getTime() <=
            new Date(stringArray[1]).getTime()
        );
      });
      this.setState({
        reports: filteredReports,
        loaded: true,
        start: stringArray[0],
        end: stringArray[1],
      });
    }
  };

  handleChangeFrom = (event) => {
    const momentObject = moment(event);
    this.setState({ from: momentObject.toDate() });
  };

  handleChangeTo = (event) => {
    const momentObject = moment(event);
    this.setState({ to: momentObject.toDate() });
  };

  handleChangeSelectedForm = (event) => {
    this.setState({ selectedForm: event });
  };

  handleChangeSelectedProject = (event) => {
    this.setState({ selectedProject: event });
  };

  submitForm = async () => {
    const dataToSend = {
      from: this.state.from,
      to: this.state.to,
      selectedForm: this.state.selectedForm,
    };
    if (this.state.selectedProject !== -1) {
      dataToSend.projectId = this.state.selectedProject;
    }

    await axios
      .post("/1.0.0/report/export", dataToSend, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        const selectedForm = this.props.forms.filter(
          (form) => form.id === this.state.selectedForm
        )[0];
        const extname = path.extname(selectedForm.exportFile);

        const blob = new Blob([response.data]);

        let link = document.createElement("a");
        let url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", `reports${extname}`);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch(async (err) => {
        console.log(err);
        console.log(err.response);
        const blob = new Blob([err.response.data]);
        const text = await blob.text();
        if (text.length > 0) {
          const parsedText = JSON.parse(text);
          console.log(text);
          if (parsedText.code === 4001) {
            message.error(
              `Fehler während der Export! Gemeinsame Formel schlägt in Zelle ${parsedText.data.errorCell} fehl!`,
              10
            );
          } else {
            message.error("Fehler während der Export!");
          }
        } else {
          message.error("Fehler während der Export!");
        }
      });
  };

  handleDeleteReport = async (data) => {
    await axios
      .delete("/1.0.0/report", {
        params: { sessionId: data.sessionId },
      })
      .then(async () => {
        message.success("Löschen erfolgreich");
        await this.getAllReports();
      })
      .catch(() => {
        message.error("Fehler beim Löschen");
      });
  };

  render() {
    let { sortedInfo } = this.state;
    sortedInfo = sortedInfo || {};

    const columns = [
      {
        title: "Projekt",
        dataIndex: "name",
        key: "name",
        sorter: (a, b) => a.name.localeCompare(b.name),
        sortOrder: sortedInfo.columnKey === "name" && sortedInfo.order,
        ...this.getColumnSearchProps("name", "Projektname"),
      },
      {
        title: "Formular",
        dataIndex: "formName",
        key: "formName",
        sorter: (a, b) => a.name.localeCompare(b.name),
        sortOrder: sortedInfo.columnKey === "formName" && sortedInfo.order,
        ...this.getColumnSearchProps("formName", "Formular"),
      },
      {
        title: "Benutzer",
        dataIndex: "fullName",
        key: "fullName",
        sorter: (a, b) => a.fullName.localeCompare(b.fullName),
        sortOrder: sortedInfo.columnKey === "fullName" && sortedInfo.order,
        ...this.getColumnSearchProps("fullName", "Benutzername"),
      },
      {
        title: "Datum",
        dataIndex: "date",
        key: "date",
        filteredValue: [this.state.start, this.state.end] || null,
        sorter: (a, b) => a.date.localeCompare(b.date),
        sortOrder: sortedInfo.columnKey === "date" && sortedInfo.order,
        /* onFilter: (value, record) => {
          console.log(value);
          return (
            new Date(record["date"]).getTime() >
              new Date(this.state.start).getTime() &&
            new Date(record["date"]).getTime() <
              new Date(this.state.end).getTime()
          );

          /* record['date']
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase()), 
        }, */
      },
      {
        title: "Löschen",
        key: "delete",
        render: (string, data) => {
          return (
            <Space size="middle">
              <Button danger onClick={() => this.handleDeleteReport(data)}>
                Löschen
              </Button>
            </Space>
          );
        },
      },
    ];
    return (
      <div>
        <Row>
          <Col span={24} style={{ padding: "1em" }}>
            {this.props.match.params.id && (
              <NavLink to="/fs/projekte">
                <Button type="primary" style={{ margin: "0.5em" }}>
                  <CaretLeftFilled />
                  Zurück
                </Button>
              </NavLink>
            )}
            <Button onClick={this.exportCSV} style={{ margin: "0.5em" }}>
              <FileExcelFilled />
              Alles exportieren
            </Button>
            {/* <Button onClick={this.exportSpecial} style={{ margin: "0.5em" }}>
              <FileExcelFilled />
              Export Brandabschottungen
            </Button> */}
            <Button onClick={this.handleOpenModal} style={{ margin: "0.5em" }}>
              <FileExcelFilled />
              Rapporte exportieren
            </Button>
          </Col>
        </Row>
        <Row>
          <Col span={24} style={{ padding: "1em" }}>
            <h1>Gesendete Rapporte</h1>
            <div style={{ marginBottom: "1em" }}>
              <RangePicker
                value={[moment(this.state.start), moment(this.state.end)]}
                onChange={this.handleChangeRange}
              />
            </div>
            <Table
              columns={columns}
              dataSource={this.state.reports}
              locale={{ emptyText: "Keine Daten" }}
              loading={!this.props.status && !this.state.loaded}
              pagination={{
                position: ["bottomCenter"],
                pageSize: 10,
              }}
              //size="middle"
              onChange={this.handleChangeTable}
            />
          </Col>
        </Row>
        <Modal
          maskClosable={false}
          visible={this.state.visible}
          title="Rapporte"
          footer={null}
          onCancel={this.onCloseModal}
          width={850}
        >
          <div>
            {this.state.selectedReport.map((report) => {
              return (
                <div key={report.id}>
                  <div
                    style={{ fontWeight: "bold", textTransform: "capitalize" }}
                  >
                    {report.sheetItem ? report.sheetItem.itemLabel + ":" : ""}
                  </div>
                  {report.typeId !== 7 ? (
                    <div>{report.value}</div>
                  ) : (
                    <div>
                      <img
                        src={
                          process.env.REACT_APP_BASE_URL +
                          "/1.0.0/report/reportfiles/" +
                          report.value +
                          "?token=" +
                          this.props.token.split(" ")[1]
                        }
                        alt="Bild"
                        width={800}
                      />
                      {report.value}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </Modal>
        <Modal
          maskClosable={false}
          visible={this.state.exportVisible}
          title="Export"
          footer={null}
          onCancel={this.handleCloseModal}
          width={850}
          forceRender
        >
          <div>
            <Form
              ref={this.formRef}
              {...formItemLayout}
              name="export"
              onFinish={this.submitForm}
              scrollToFirstError
            >
              <Form.Item
                name="from"
                label="Von"
                rules={[
                  {
                    required: true,
                    message: "Bitte geben Sie den Von Datum ein!",
                  },
                ]}
              >
                <DatePicker
                  value={moment(this.state.from)}
                  onChange={this.handleChangeFrom}
                />
              </Form.Item>
              <Form.Item
                name="to"
                label="Bis"
                rules={[
                  {
                    required: true,
                    message: "Bitte geben Sie den Bis Datum ein!",
                  },
                ]}
              >
                <DatePicker
                  value={moment(this.state.to)}
                  onChange={this.handleChangeTo}
                />
              </Form.Item>
              <Form.Item
                name="form"
                label="Formular"
                rules={[
                  {
                    required: true,
                    message: "Bitte geben Sie den Formular ein!",
                  },
                ]}
              >
                <Select
                  value={this.state.selectedForm}
                  onChange={this.handleChangeSelectedForm}
                >
                  {this.props.forms
                    .filter(
                      (form) =>
                        form.reports.length > 0 && form.exportFile !== null
                    )
                    .map((form) => {
                      return (
                        <Select.Option key={form.id} value={form.id}>
                          {form.name}
                        </Select.Option>
                      );
                    })}
                </Select>
              </Form.Item>
              <Form.Item
                name="project"
                label="Projekt"
                rules={[
                  {
                    required: true,
                    message: "Bitte geben Sie den Projekt ein!",
                  },
                ]}
                initialValue={this.state.selectedProject}
              >
                <Select
                  value={this.state.selectedProject}
                  onChange={this.handleChangeSelectedProject}
                >
                  <Select.Option value={-1} key={-1}>
                    Alle Projekte
                  </Select.Option>
                  {this.props.projects.map((project) => {
                    return (
                      <Select.Option key={project.id} value={project.id}>
                        {project.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <Form.Item>
                <Button htmlType="submit" type="primary">
                  Export
                </Button>
              </Form.Item>
            </Form>
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  token: state.loginReducer.token,
  user: state.loginReducer.user,
  reports: state.reportsReducer.reports,
  status: state.reportsReducer.status,
  forms: state.formsReducer.forms,
  projects: state.projectReducer.projects,
});

const Reports = connect(mapStateToProps, mapDispatchToProps)(ConnectedReports);
export default Reports;
