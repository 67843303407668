import { createStore, combineReducers, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import loginReducer from "../reducers/login";
import usersReducer from "../reducers/users";
import projectReducer from "../reducers/project";
import manufacturerReducer from "../reducers/manufacturer";
import instructionReducer from "../reducers/instruction";
import certificateReducer from "../reducers/certificate";
import formsReducer from "../reducers/forms";
import blueprintReducer from "../reducers/blueprint";
import projectdocuReducer from "../reducers/projectdocu";
import reportsReducer from "../reducers/reports";
import companyReducer from "../reducers/company";

const persistConfig = {
  key: "root",
  storage: storage,
  blacklist: [
    "usersReducer",
    "projectReducer",
    "manufacturerReducer",
    "instructionReducer",
    "certificateReducer",
    "formsReducer",
    "blueprintReducer",
    "projectdocuReducer",
    "reportsReducer",
    "companyReducer",
  ],
};

const rootReducer = combineReducers({
  loginReducer,
  usersReducer,
  projectReducer,
  manufacturerReducer,
  instructionReducer,
  certificateReducer,
  formsReducer,
  blueprintReducer,
  projectdocuReducer,
  reportsReducer,
  companyReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(
  persistedReducer,
  composeWithDevTools(applyMiddleware(thunk))
);

export const persistor = persistStore(store);
