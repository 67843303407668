import axios from "axios";
import { PROJECT_ACTIONS } from "../constants/action-types";

export const getAllProjects = (userId, companyId) => {
  return async (dispatch) => {
    dispatch({ type: PROJECT_ACTIONS.PROJECTS_LOADING });
    await axios
      .get("/1.0.0/projects", {
        //headers: { Authorization: token },
        params: { userId: userId, companyId: companyId },
      })
      .then((response) => {
        dispatch({
          type: PROJECT_ACTIONS.PROJECTS_LOADED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: PROJECT_ACTIONS.PROJECTS_ERROR,
          payload: err.response.data,
        });
        console.log(err.message);
      });
  };
};

export const createProject = (project) => {
  return async (dispatch) => {
    dispatch({ type: PROJECT_ACTIONS.PROJECT_SAVE_START });
    await axios
      .post("/1.0.0/projects", project, {
        //headers: { Authorization: token },
      })
      .then(() => {
        dispatch({ type: PROJECT_ACTIONS.PROJECT_SAVE_SUCCESS });
      })
      .catch((err) => {
        dispatch({
          type: PROJECT_ACTIONS.PROJECT_SAVE_ERROR,
          payload: err.response.data,
        });
      });
  };
};

export const deleteProject = (projectId) => {
  return async () => {
    await axios.delete("/1.0.0/projects/" + projectId).catch((err) => {
      console.log(err.message);
    });
  };
};
