export const LOGIN_ACTIONS = {
  TOKEN_LOADING: "TOKEN_LOADING",
  TOKEN_LOADED: "TOKEN_LOADED",
  TOKEN_ERROR: "TOKEN_ERROR",
  LOGOUT: "LOGOUT",
};

export const USERS_ACTIONS = {
  USERS_LOADING: "USERS_LOADING",
  USERS_LOADED: "USERS_LOADED",
  USERS_ERROR: "USERS_ERROR",
  USER_REGISTER_START: "USER_REGISTER_START",
  USER_REGISTER_SUCCESS: "USER_REGISTER_SUCCESS",
  USER_REGISTER_ERROR: "USER_REGISTER_ERROR",
  GROUPS_LOADING: "GROUPS_LOADING",
  GROUPS_LOADED: "GROUPS_LOADED",
  GROUPS_ERROR: "GROUPS_ERROR",
};

export const PROJECT_ACTIONS = {
  PROJECTS_LOADING: "PROJECTS_LOADING",
  PROJECTS_LOADED: "PROJECTS_LOADED",
  PROJECTS_ERROR: "PROJECTS_ERROR",
  PROJECT_SAVE_START: "PROJECT_SAVE_START",
  PROJECT_SAVE_SUCCESS: "PROJECT_SAVE_SUCCESS",
  PROJECT_SAVE_ERROR: "PROJECT_SAVE_ERROR",
};

export const MANUFACTURER_ACTIONS = {
  MANUFACTURER_SAVE_START: "MANUFACTURER_SAVE_START",
  MANUFACTURER_SAVE_SUCCESS: "MANUFACTURER_SAVE_SUCCESS",
  MANUFACTURER_SAVE_ERROR: "MANUFACTURER_SAVE_ERROR",
  MANUFACTURERS_LOADING: "MANUFACTURERS_LOADING",
  MANUFACTURERS_LOADED: "MANUFACTURERS_LOADED",
  MANUFACTURERS_ERROR: "MANUFACTURERS_ERROR",
};

export const INSTRUCTION_ACTIONS = {
  INSTRUCTION_SAVE_START: "INSTRUCTION_SAVE_START",
  INSTRUCTION_SAVE_SUCCESS: "INSTRUCTION_SAVE_SUCCESS",
  INSTRUCTION_SAVE_ERROR: "INSTRUCTION_SAVE_ERROR",
  INSTRUCTIONS_LOADING: "INSTRUCTIONS_LOADING",
  INSTRUCTIONS_LOADED: "INSTRUCTIONS_LOADED",
  INSTRUCTIONS_ERROR: "INSTRUCTIONS_ERROR",
};

export const CERTIFICATE_ACTIONS = {
  CERTIFICATE_SAVE_START: "CERTIFICATE_SAVE_START",
  CERTIFICATE_SAVE_SUCCESS: "CERTIFICATE_SAVE_SUCCESS",
  CERTIFICATE_SAVE_ERROR: "CERTIFICATE_SAVE_ERROR",
  CERTIFICATES_LOADING: "CERTIFICATES_LOADING",
  CERTIFICATES_LOADED: "CERTIFICATES_LOADED",
  CERTIFICATES_ERROR: "CERTIFICATES_ERROR",
};

export const BLUEPRINT_ACTIONS = {
  BLUEPRINT_SAVE_START: "BLUEPRINT_SAVE_START",
  BLUEPRINT_SAVE_SUCCESS: "BLUEPRINT_SAVE_SUCCESS",
  BLUEPRINT_SAVE_ERROR: "BLUEPRINT_SAVE_ERROR",
  BLUEPRINTS_LOADING: "BLUEPRINTS_LOADING",
  BLUEPRINTS_LOADED: "BLUEPRINTS_LOADED",
  BLUEPRINTS_ERROR: "BLUEPRINTS_ERROR",
};

export const PROJECTDOCU_ACTIONS = {
  PROJECTDOCU_SAVE_START: "PROJECTDOCU_SAVE_START",
  PROJECTDOCU_SAVE_SUCCESS: "PROJECTDOCU_SAVE_SUCCESS",
  PROJECTDOCU_SAVE_ERROR: "PROJECTDOCUT_SAVE_ERROR",
  PROJECTDOCUS_LOADING: "PROJECTDOCUS_LOADING",
  PROJECTDOCUS_LOADED: "PROJECTDOCUS_LOADED",
  PROJECTDOCUS_ERROR: "PROJECTDOCUS_ERROR",
};

export const FORMS_ACTIONS = {
  FORM_SAVE_START: "FORM_SAVE_START",
  FORM_SAVE_SUCCESS: "FORM_SAVE_SUCCESS",
  FORM_SAVE_ERROR: "FORM_SAVE_ERROR",
  FORMS_LOADING: "FORMS_LOADING",
  FORMS_LOADED: "FORMS_LOADED",
  FORMS_ERROR: "FORMS_ERROR",
};

export const REPORTS_ACTIONS = {
  REPORT_SAVE_START: "REPORT_SAVE_START",
  REPORT_SAVE_SUCCESS: "REPORT_SAVE_SUCCESS",
  REPORT_SAVE_ERROR: "REPORT_SAVE_ERROR",
  REPORTS_LOADING: "REPORTS_LOADING",
  REPORTS_LOADED: "REPORTS_LOADED",
  REPORTS_ERROR: "REPORTS_ERROR",
};

export const COMPANY_ACTIONS = {
  COMPANY_SAVE_START: "COMPANY_SAVE_START",
  COMPANY_SAVE_SUCCESS: "COMPANY_SAVE_SUCCESS",
  COMPANY_SAVE_ERROR: "COMPANY_SAVE_ERROR",
  COMPANY_LOADING: "COMPANY_LOADING",
  COMPANY_LOADED: "COMPANY_LOADED",
  COMPANY_ERROR: "COMPANY_ERROR",
};
