import { CERTIFICATE_ACTIONS } from "../constants/action-types";

const initialState = {
  certificates: [],
  message: {},
  status: false,
  saveStatus: false,
};

const certificateReducer = (state = initialState, action) => {
  switch (action.type) {
    case CERTIFICATE_ACTIONS.CERTIFICATE_SAVE_START:
      return Object.assign({}, state, {
        saveStatus: false,
      });
    case CERTIFICATE_ACTIONS.CERTIFICATE_SAVE_SUCCESS:
      return Object.assign({}, state, {
        saveStatus: true,
      });
    case CERTIFICATE_ACTIONS.CERTIFICATE_SAVE_ERROR:
      return Object.assign({}, state, {
        saveStatus: false,
        message: action.payload,
      });
    case CERTIFICATE_ACTIONS.CERTIFICATES_LOADING:
      return Object.assign({}, state, {
        status: false,
        message: {},
        certificates: [],
      });
    case CERTIFICATE_ACTIONS.CERTIFICATES_LOADED:
      return Object.assign({}, state, {
        status: true,
        message: {},
        certificates: action.payload.certificates,
      });
    case CERTIFICATE_ACTIONS.CERTIFICATES_ERROR:
      return Object.assign({}, state, {
        status: true,
        message: action.payload.message,
        certificates: [],
      });

    default:
      return state;
  }
};

export default certificateReducer;
