import { CERTIFICATE_ACTIONS } from "../constants/action-types";
import axios from "axios";

export const getAllCertificates = () => {
  return async (dispatch) => {
    dispatch({ type: CERTIFICATE_ACTIONS.CERTIFICATES_LOADING });
    await axios
      .get("/1.0.0/certificate")
      .then((response) => {
        dispatch({
          type: CERTIFICATE_ACTIONS.CERTIFICATES_LOADED,
          payload: response.data,
        });
      })
      .catch((err) => {
        console.log(err.message);
        dispatch({
          type: CERTIFICATE_ACTIONS.CERTIFICATES_ERROR,
          payload: err.response.data,
        });
      });
  };
};

export const deleteCertificate = (certificateId) => {
  return async () => {
    await axios.delete("/1.0.0/certificate/" + certificateId).catch((err) => {
      console.log(err.message);
    });
  };
};

export const saveCertificate = (formData) => {
  return async (dispatch) => {
    dispatch({ type: CERTIFICATE_ACTIONS.CERTIFICATE_SAVE_START });
    await axios
      .post("/1.0.0/certificate", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then(() => {
        dispatch({ type: CERTIFICATE_ACTIONS.CERTIFICATE_SAVE_SUCCESS });
      })
      .catch((err) => {
        dispatch({
          type: CERTIFICATE_ACTIONS.CERTIFICATE_SAVE_ERROR,
          payload: err.response.data,
        });
      });
  };
};
