import Axios from "axios";
import { COMPANY_ACTIONS } from "../constants/action-types";

export const getAllCompanies = () => {
  return async (dispatch) => {
    dispatch({ type: COMPANY_ACTIONS.COMPANY_LOADING });
    await Axios.get("/1.0.0/company", {
      //headers: { Authorization: token },
    })
      .then((response) => {
        dispatch({
          type: COMPANY_ACTIONS.COMPANY_LOADED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: COMPANY_ACTIONS.COMPANY_ERROR,
          payload: err.response.data,
        });
        console.log(err.message);
      });
  };
};

export const saveCompany = (data) => {
  return async (dispatch) => {
    dispatch({ type: COMPANY_ACTIONS.COMPANY_SAVE_START });
    await Axios.post("/1.0.0/company", data, {})
      .then(() => {
        dispatch({ type: COMPANY_ACTIONS.COMPANY_SAVE_SUCCESS });
      })
      .catch((err) => {
        dispatch({
          type: COMPANY_ACTIONS.COMPANY_SAVE_ERROR,
          payload: err.response.data,
        });
      });
  };
};

export const modifyCompany = (data) => {
  return async (dispatch) => {
    dispatch({ type: COMPANY_ACTIONS.COMPANY_SAVE_START });
    await Axios.put("/1.0.0/company", data, {})
      .then(() => {
        dispatch({ type: COMPANY_ACTIONS.COMPANY_SAVE_SUCCESS });
      })
      .catch((err) => {
        dispatch({
          type: COMPANY_ACTIONS.COMPANY_SAVE_ERROR,
          payload: err.response.data,
        });
      });
  };
};

export const deleteCompany = (id) => {
  return async () => {
    await Axios.delete("/1.0.0/company/" + id);
  };
};
