import { REPORTS_ACTIONS } from "../constants/action-types";
import axios from "axios";

export const getAllReports = (projectId, userId, companyId) => {
  return async (dispatch) => {
    dispatch({ type: REPORTS_ACTIONS.REPORTS_LOADING });
    await axios
      .get("/1.0.0/report", {
        params: { projectId: projectId, userId: userId, companyId: companyId },
      })
      .then((response) => {
        dispatch({
          type: REPORTS_ACTIONS.REPORTS_LOADED,
          payload: response.data,
        });
      })
      .catch((err) => {
        console.log(err.message);
        dispatch({
          type: REPORTS_ACTIONS.REPORTS_ERROR,
          payload: err.response.data,
        });
      });
  };
};
