import { CloseCircleFilled, PlusOutlined, SaveFilled } from "@ant-design/icons";
import { Button, Col, Input, Modal, Row, Space, Table, Form } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  deleteCompany,
  getAllCompanies,
  modifyCompany,
  saveCompany,
} from "../../actions/company";
const mapDispatchToProps = (dispatch) => {
  return {
    getAllCompanies: () => dispatch(getAllCompanies()),
    saveCompany: (data) => dispatch(saveCompany(data)),
    modifyCompany: (data) => dispatch(modifyCompany(data)),
    deleteCompany: (id) => dispatch(deleteCompany(id)),
  };
};
class ConnectedCompany extends Component {
  formRef = React.createRef();

  state = {
    selectedId: null,
    modalVisible: false,
    name: "",
    modify: false,
  };

  componentDidMount = async () => {
    await this.props.getAllCompanies();
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleOpenNewCompany = () => {
    this.setState({ modalVisible: true });
  };

  handleOpenModifyCompany = (record) => {
    this.setState({
      modalVisible: true,
      name: record.name,
      selectedId: record.id,
      modify: true,
    });
    this.formRef.current.setFieldsValue({ name: record.name });
  };

  handleDeleteCompany = async (id) => {
    await this.props.deleteCompany(id);
    await this.props.getAllCompanies();
  };

  handleCancel = () => {
    this.setState({
      selectedId: null,
      modalVisible: false,
      name: "",
      modify: false,
    });
    this.formRef.current.resetFields();
  };

  submitForm = async () => {
    if (this.state.modify) {
      const dataToSend = {
        id: this.state.selectedId,
        name: this.state.name,
      };
      await this.props.modifyCompany(dataToSend);
    } else {
      const dataToSend = {
        name: this.state.name,
      };
      await this.props.saveCompany(dataToSend);
    }
    await this.props.getAllCompanies();
    this.handleCancel();
  };

  render() {
    const formItemLayout = {
      labelCol: {
        xs: {
          span: 24,
        },
        sm: {
          span: 8,
        },
      },
      wrapperCol: {
        xs: {
          span: 24,
        },
        sm: {
          span: 16,
        },
      },
    };
    const columns = [
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "Ändern",
        key: "modify",
        render: (string, data) => {
          return (
            <Space size="middle">
              <Button onClick={() => this.handleOpenModifyCompany(data)}>
                Ändern
              </Button>
            </Space>
          );
        },
      },
      {
        title: "Löschen",
        key: "delete",
        render: (string, data) => {
          return (
            <Space size="middle">
              <Button danger onClick={() => this.handleDeleteCompany(data.id)}>
                Löschen
              </Button>
            </Space>
          );
        },
      },
    ];
    return (
      <div>
        <Row>
          <Col span={24} style={{ padding: "1em" }}>
            <h1>Firmen</h1>
          </Col>
        </Row>
        <Row>
          <Col span={24} style={{ padding: "1em" }}>
            <Button
              type="primary"
              style={{ float: "right" }}
              onClick={this.handleOpenNewCompany}
            >
              <PlusOutlined />
              Neue Firma
            </Button>
          </Col>
        </Row>
        <Row>
          <Col span={24} style={{ padding: "1em" }}>
            <Table
              columns={columns}
              dataSource={this.props.companies}
              rowKey="id"
              pagination={{
                position: ["bottomCenter"],
                pageSize: 10,
                showSizeChanger: false,
              }}
            />
          </Col>
        </Row>
        <Modal
          title={this.state.modify === true ? "Ändern" : "Neue Firma"}
          centered
          visible={this.state.modalVisible}
          onCancel={this.handleCancel}
          footer={null}
          forceRender
        >
          <Form
            ref={this.formRef}
            {...formItemLayout}
            name="company"
            onFinish={this.submitForm}
            scrollToFirstError
          >
            <Form.Item
              name="name"
              label="Firmenname"
              rules={[
                {
                  required: true,
                  message: "Bitte geben Sie den Firmenname ein!",
                  whitespace: true,
                },
              ]}
            >
              <Input
                name="name"
                value={this.state.name}
                onChange={this.handleChange}
              />
            </Form.Item>
            <Form.Item>
              <Button
                htmlType="submit"
                type="primary"
                style={{ margin: "0.5em" }}
              >
                <SaveFilled />
                Speichern
              </Button>
              <Button
                htmlType="reset"
                type="default"
                onClick={this.handleCancel}
                style={{ margin: "0.5em" }}
              >
                <CloseCircleFilled />
                Stornieren
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  companies: state.companyReducer.companies,
});
const Company = connect(mapStateToProps, mapDispatchToProps)(ConnectedCompany);
export default Company;
