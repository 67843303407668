import { PROJECTDOCU_ACTIONS } from "../constants/action-types";

const initialState = {
  projectdocus: [],
  message: {},
  status: false,
  saveStatus: false,
};

const projectdocuReducer = (state = initialState, action) => {
  switch (action.type) {
    case PROJECTDOCU_ACTIONS.PROJECTDOCU_SAVE_START:
      return Object.assign({}, state, {
        saveStatus: false,
      });
    case PROJECTDOCU_ACTIONS.PROJECTDOCU_SAVE_SUCCESS:
      return Object.assign({}, state, {
        saveStatus: true,
      });
    case PROJECTDOCU_ACTIONS.PROJECTDOCU_SAVE_ERROR:
      return Object.assign({}, state, {
        saveStatus: false,
        message: action.payload,
      });
    case PROJECTDOCU_ACTIONS.PROJECTDOCUS_LOADING:
      return Object.assign({}, state, {
        status: false,
        message: {},
        projectdocus: [],
      });
    case PROJECTDOCU_ACTIONS.PROJECTDOCUS_LOADED:
      return Object.assign({}, state, {
        status: true,
        message: {},
        projectdocus: action.payload.projectdocus,
      });
    case PROJECTDOCU_ACTIONS.PROJECTDOCUS_ERROR:
      return Object.assign({}, state, {
        status: true,
        message: action.payload.message,
        projectdocus: [],
      });

    default:
      return state;
  }
};

export default projectdocuReducer;
