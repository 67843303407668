/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import {
  Row,
  Col,
  Space,
  Button,
  Table,
  Input,
  message,
  Form,
  Select,
  Modal,
  Popconfirm,
} from "antd";
import { connect } from "react-redux";
import Dragger from "antd/lib/upload/Dragger";
import {
  InboxOutlined,
  SearchOutlined,
  SaveFilled,
  CloseCircleFilled,
} from "@ant-design/icons";
import { getAllManufacturers } from "../../actions/manufacturer";
import { Document, Page } from "react-pdf/dist/entry.webpack";
import {
  getAllCertificates,
  deleteCertificate,
  saveCertificate,
} from "../../actions/certificate";
import Highlighter from "react-highlight-words";

const mapDispatchToProps = (dispatch) => {
  return {
    getAllManufacturers: () => dispatch(getAllManufacturers()),
    getAllCertificates: () => dispatch(getAllCertificates()),
    deleteCertificate: (certificateId) =>
      dispatch(deleteCertificate(certificateId)),
    saveCertificate: (formData) => dispatch(saveCertificate(formData)),
  };
};

const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};

class ConnectedCertificates extends Component {
  formRef = React.createRef();

  state = {
    name: "",
    file: [],
    certificates: [],
    loaded: false,
    manufacturer: null,
    visible: false,
    pdfLink: "",
    numPages: null,
    pageNumber: 1,
    modify: false,
    selectedId: null,
  };

  componentDidMount = async () => {
    await this.props.getAllManufacturers();
    await this.getAllCertificates();
  };

  getAllCertificates = async () => {
    this.setState({ loaded: false });
    await this.props.getAllCertificates();
    if (this.props.status) {
      const certificates = this.props.certificates.map((certificate) => {
        return {
          key: certificate.id,
          id: certificate.id,
          name: certificate.name,
          filename: certificate.filename,
          manufacturer: certificate.manufacturer,
          manufacturerName: certificate.manufacturer.name,
        };
      });
      this.setState({ loaded: true, certificates: certificates });
    }
  };

  changeUploadedFile = (info) => {
    const { status } = info.file;
    if (status !== "uploading") {
      console.log(info.file, info.fileList);
    }
    if (status === "done") {
      message.success(`${info.file.name} file uploaded successfully.`);
      //this.setState({ file: info.file });
    } else if (status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleChangeManufacturer = (event) => {
    this.setState({ manufacturer: event });
  };

  removeFile = () => {
    this.setState({ file: [] });
  };

  beforeUpload = (file) => {
    this.setState({ file: [file] });
    return false;
  };

  submitForm = async () => {
    const formData = new FormData();
    if (this.state.modify === true) {
      formData.set("id", this.state.selectedId);
    }
    formData.set("name", this.state.name);
    formData.set("manufacturer", this.state.manufacturer);
    formData.append("file", this.state.file[0]);
    await this.props.saveCertificate(formData);
    if (this.props.saveStatus) {
      this.setState({
        name: "",
        selectedId: null,
        modify: false,
        manufacturer: null,
        file: [],
      });
      this.formRef.current.resetFields();
      await this.getAllCertificates();
      message.success("Erfolgreich speichern");
    } else {
      if (this.props.message.code === 1007) {
        message.error("Zertifikate mit diesem Namen existiert bereits!");
      } else {
        message.error("Fehler speichern");
      }
    }
  };

  handleDeleteCertificate = async (certificate) => {
    await this.props.deleteCertificate(certificate.id);
    await this.getAllCertificates();
  };

  handleClickFile = (record) => {
    //console.log(record);
    const fileLocation =
      process.env.REACT_APP_BASE_URL +
      "/1.0.0/certificate/certificates/" +
      record.filename +
      "?token=" +
      this.props.token.split(" ")[1];
    this.setState({ visible: true, pdfLink: fileLocation });
  };

  handleCancel = () => {
    this.setState({
      visible: false,
      pdfLink: "",
      numPages: null,
      pageNumber: 1,
    });
  };

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  };

  getColumnSearchProps = (dataIndex, searchLabel) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Suche ${searchLabel}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Suche
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 100 }}
          >
            Zurücksetzen
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text, record) =>
      this.state.searchedColumn === dataIndex ? (
        <Space align="center">
          {dataIndex === "name" ? (
            <a onClick={() => this.handleClickFile(record)}>
              <Highlighter
                highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
                searchWords={[this.state.searchText]}
                autoEscape
                textToHighlight={text.toString()}
              />
            </a>
          ) : (
            <Highlighter
              highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
              searchWords={[this.state.searchText]}
              autoEscape
              textToHighlight={text.toString()}
            />
          )}
        </Space>
      ) : (
        <Space align="center">
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
          {dataIndex === "name" ? (
            <a onClick={() => this.handleClickFile(record)}>{text}</a>
          ) : (
            text
          )}
        </Space>
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  handleModify = (record) => {
    this.setState({
      name: record.name,
      selectedId: record.id,
      manufacturer: record.manufacturer.id,
      modify: true,
    });
    this.formRef.current.setFieldsValue({
      name: record.name,
      manufacturer: record.manufacturer.id,
    });
  };

  handleCancelUpdate = () => {
    this.setState({
      name: "",
      selectedId: null,
      modify: false,
      manufacturer: null,
    });
    this.formRef.current.resetFields();
  };

  render() {
    const columns = [
      {
        title: "Zertifikate",
        dataIndex: "name",
        key: "name",
        render: (name, record) => {
          return (
            <Space align="center">
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
              <a onClick={() => this.handleClickFile(record)}>{name}</a>
            </Space>
          );
        },
        ...this.getColumnSearchProps("name", "name"),
      },
      {
        title: "Hersteller",
        dataIndex: "manufacturerName",
        key: "manufacturerName",
        ...this.getColumnSearchProps("manufacturerName", "hersteller"),
      },
      {
        title: "Ändern",
        key: "modify",
        render: (text, record) => (
          <Space size="middle">
            <Button onClick={() => this.handleModify(record)}>Ändern</Button>
          </Space>
        ),
      },
      {
        title: "Löschen",
        key: "delete",
        render: (text, record) => (
          <Space size="middle">
            <Popconfirm
              title="Sind Sie sicher, dieses Zertifikat zu löschen?"
              onConfirm={() => this.handleDeleteCertificate(record)}
              okText="Ja"
              cancelText="Nein"
            >
              <Button>
                Löschen
              </Button>
            </Popconfirm>
          </Space>
        ),
      },
    ];
    return (
      <div>
        <Row>
          <Col span={12} style={{ padding: "1em" }}>
            <h1>Zertifikaten</h1>
            <Table
              columns={columns}
              dataSource={this.state.certificates}
              locale={{ emptyText: "Keine Daten" }}
              loading={!this.props.status && !this.state.loaded}
              pagination={{
                position: ["bottomCenter"],
                pageSize: 10,
              }}
            />
          </Col>
          <Col span={12} style={{ padding: "1em" }}>
            <h1>Neue Zertifikate</h1>
            <Form
              ref={this.formRef}
              {...formItemLayout}
              name="instruction"
              onFinish={this.submitForm}
              scrollToFirstError
            >
              <Form.Item
                name="name"
                label="Name"
                rules={[
                  {
                    required: true,
                    message: "Bitte geben Sie den Zertifikatenamen ein!",
                    whitespace: true,
                  },
                ]}
              >
                <Input
                  name="name"
                  value={this.state.name}
                  onChange={this.handleChange}
                />
              </Form.Item>
              <Form.Item
                name="manufacturer"
                label="Hersteller"
                rules={[
                  {
                    required: true,
                    message: "Bitte geben Sie den Hersteller ein!",
                  },
                ]}
              >
                <Select
                  name="manufacturer"
                  value={this.state.manufacturer}
                  onChange={this.handleChangeManufacturer}
                >
                  {this.props.manufacturers.map((manufacturer) => {
                    return (
                      <Option value={manufacturer.id} key={manufacturer.id}>
                        {manufacturer.name}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <Form.Item
                name="file"
                label="Datei"
                rules={[
                  {
                    required: !this.state.modify,
                    message: "Bitte geben Sie den Datei ein!",
                  },
                ]}
              >
                <Dragger
                  name="file"
                  multiple={false}
                  onChange={this.changeUploadedFile}
                  onRemove={this.removeFile}
                  accept=".pdf"
                  beforeUpload={this.beforeUpload}
                  fileList={this.state.file}
                >
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">
                    Klicken oder ziehen Sie eine Datei in diesen Bereich, um sie
                    hochzuladen
                  </p>
                </Dragger>
              </Form.Item>
              <Form.Item>
                <Button
                  htmlType="submit"
                  type="primary"
                  style={{ margin: "0.5em" }}
                >
                  <SaveFilled />
                  Speichern
                </Button>
                {this.state.modify && (
                  <Button
                    htmlType="reset"
                    type="default"
                    onClick={this.handleCancelUpdate}
                    style={{ margin: "0.5em" }}
                  >
                    <CloseCircleFilled />
                    Stornieren
                  </Button>
                )}
              </Form.Item>
            </Form>
          </Col>
        </Row>
        <Modal
          maskClosable={false}
          visible={this.state.visible}
          title="Montageanleitung"
          //onOk={this.handleSave}
          onCancel={this.handleCancel}
          footer={[
            this.state.numPages > 1 && (
              <Button
                onClick={() =>
                  this.setState({ pageNumber: this.state.pageNumber - 1 })
                }
                disabled={this.state.pageNumber === 1}
              >
                Vorherige Seite
              </Button>
            ),
            this.state.numPages > 1 && (
              <Button
                onClick={() =>
                  this.setState({ pageNumber: this.state.pageNumber + 1 })
                }
                disabled={this.state.pageNumber === this.state.numPages}
              >
                Nächste Seite
              </Button>
            ),
            <Button key="back" onClick={this.handleCancel}>
              Zurück
            </Button>,
            <Button
              key="submit"
              type="primary"
              //onClick={this.handleDownloadPdf}
              href={this.state.pdfLink}
              target="_blank"
              rel="noopener noreferrer"
              download
            >
              Herunterladen
            </Button>,
          ]}
          width={800}
          centered={true}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              overflowY: "auto",
              height: "500px",
            }}
          >
            <div>
              <Document
                file={this.state.pdfLink}
                onLoadSuccess={this.onDocumentLoadSuccess}
              >
                <Page pageNumber={this.state.pageNumber} height={800} />
              </Document>
            </div>
            <p>
              {this.state.pageNumber} / {this.state.numPages}
            </p>
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  manufacturers: state.manufacturerReducer.manufacturers,
  token: state.loginReducer.token,
  certificates: state.certificateReducer.certificates,
  status: state.certificateReducer.status,
  saveStatus: state.certificateReducer.saveStatus,
  message: state.certificateReducer.message,
});

const Certificates = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectedCertificates);
export default Certificates;
