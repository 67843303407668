import axios from "axios";
import { MANUFACTURER_ACTIONS } from "../constants/action-types";

export const saveManufacturer = (formData) => {
  return async (dispatch) => {
    dispatch({ type: MANUFACTURER_ACTIONS.MANUFACTURER_SAVE_START });
    await axios
      .post("/1.0.0/manufacturer", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then(() => {
        dispatch({ type: MANUFACTURER_ACTIONS.MANUFACTURER_SAVE_SUCCESS });
      })
      .catch((err) => {
        dispatch({
          type: MANUFACTURER_ACTIONS.MANUFACTURER_SAVE_ERROR,
          payload: err.response.data,
        });
      });
  };
};

export const getAllManufacturers = () => {
  return async (dispatch) => {
    dispatch({ type: MANUFACTURER_ACTIONS.MANUFACTURERS_LOADING });
    await axios
      .get("/1.0.0/manufacturer")
      .then((response) => {
        dispatch({
          type: MANUFACTURER_ACTIONS.MANUFACTURERS_LOADED,
          payload: response.data,
        });
      })
      .catch((err) => {
        console.log(err.message);
        dispatch({
          type: MANUFACTURER_ACTIONS.MANUFACTURERS_ERROR,
          payload: err.response.data,
        });
      });
  };
};

export const deleteManufacturer = (manufacturerId) => {
  return async () => {
    await axios.delete("/1.0.0/manufacturer/" + manufacturerId).catch((err) => {
      console.log(err.message);
    });
  };
};
