import { REPORTS_ACTIONS } from "../constants/action-types";

const initialState = {
  reports: [],
  message: {},
  status: false,
  saveStatus: false,
};

const reportsReducer = (state = initialState, action) => {
  switch (action.type) {
    case REPORTS_ACTIONS.REPORTS_LOADING:
      return Object.assign({}, state, {
        reports: [],
        message: "",
        status: false,
      });
    case REPORTS_ACTIONS.REPORTS_LOADED:
      return Object.assign({}, state, {
        reports: action.payload.reports,
        message: "",
        status: true,
      });
    case REPORTS_ACTIONS.REPORTS_ERROR:
      return Object.assign({}, state, {
        reports: [],
        message: action.payload.message,
        status: false,
      });
    case REPORTS_ACTIONS.REPORT_SAVE_START:
      return Object.assign({}, state, {
        saveStatus: false,
      });
    case REPORTS_ACTIONS.REPORT_SAVE_SUCCESS:
      return Object.assign({}, state, {
        saveStatus: true,
      });
    case REPORTS_ACTIONS.REPORT_SAVE_ERROR:
      return Object.assign({}, state, {
        saveStatus: false,
        message: action.payload,
      });

    default:
      return state;
  }
};

export default reportsReducer;
