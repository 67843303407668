import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Row,
  Col,
  message,
  Form,
  Input,
  Button,
  Table,
  Space,
  Popconfirm,
} from "antd";
import Dragger from "antd/lib/upload/Dragger";
import {
  InboxOutlined,
  SearchOutlined,
  SaveFilled,
  CloseCircleFilled,
} from "@ant-design/icons";
import {
  saveManufacturer,
  getAllManufacturers,
  deleteManufacturer,
} from "../../actions/manufacturer";
import Highlighter from "react-highlight-words";

const mapDispatchToProps = (dispatch) => {
  return {
    saveManufacturer: (formData) => dispatch(saveManufacturer(formData)),
    getAllManufacturers: () => dispatch(getAllManufacturers()),
    deleteManufacturer: (manufacturerId) =>
      dispatch(deleteManufacturer(manufacturerId)),
  };
};

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};

class ConnectedManufacturers extends Component {
  formRef = React.createRef();

  state = {
    name: "",
    file: [],
    manufacturers: [],
    loaded: false,
    selectedId: null,
    modify: false,
  };

  componentDidMount = async () => {
    await this.getAllManufacturers();
  };

  getAllManufacturers = async () => {
    this.setState({ loaded: false });
    await this.props.getAllManufacturers();
    if (this.props.status) {
      const manufacturers = this.props.manufacturers.map((manufacturer) => {
        return { ...manufacturer, key: manufacturer.id };
      });
      this.setState({ manufacturers: manufacturers, loaded: true });
    }
  };

  changeUploadedFile = (info) => {
    const { status } = info.file;
    if (status !== "uploading") {
      console.log(info.file, info.fileList);
    }
    if (status === "done") {
      message.success(`${info.file.name} file uploaded successfully.`);
      //this.setState({ file: info.file });
    } else if (status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  removeFile = () => {
    this.setState({ file: [] });
  };

  beforeUpload = (file) => {
    this.setState({ file: [file] });
    return false;
  };

  submitForm = async () => {
    const formData = new FormData();
    if (this.state.modify === true) {
      formData.set("id", this.state.selectedId);
    }
    formData.set("name", this.state.name);
    formData.append("file", this.state.file[0]);
    await this.props.saveManufacturer(formData);
    if (this.props.saveStatus) {
      this.setState({ name: "", selectedId: null, modify: false, file: [] });
      this.formRef.current.resetFields();
      message.success("Erfolgreich speichern!", 2);
      await this.getAllManufacturers();
    } else {
      if (this.props.message.code === 1006) {
        message.error("Hersteller mit diesem Namen existiert bereits!", 2);
      } else {
        message.error("Speichern erfolglos!", 2);
      }
    }
    this.forceUpdate();
  };

  handleDeleteManufacturer = async (manufacturer) => {
    await this.props.deleteManufacturer(manufacturer.id);
    await this.getAllManufacturers();
  };

  getColumnSearchProps = (dataIndex, searchLabel) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Suche ${searchLabel}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Suche
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 100 }}
          >
            Zurücksetzen
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text, record) =>
      this.state.searchedColumn === dataIndex ? (
        <Space align="center">
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[this.state.searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        </Space>
      ) : (
        <Space align="center">{text}</Space>
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  handleModifyManufacturer = (manufacturer) => {
    this.setState({
      name: manufacturer.name,
      selectedId: manufacturer.id,
      modify: true,
    });
    this.formRef.current.setFieldsValue({ name: manufacturer.name });
  };

  handleCancelUpdate = () => {
    this.setState({ name: "", selectedId: null, modify: false });
    this.formRef.current.resetFields();
  };

  render() {
    const columns = [
      {
        title: "Hersteller",
        dataIndex: "name",
        key: "name",
        ...this.getColumnSearchProps("name", "Hersteller"),
      },
      {
        title: "Logo",
        dataIndex: "filename",
        key: "filename",
        render: (filename) => {
          return (
            <Space align="center">
              {filename !== null ? (
                <img
                  src={
                    process.env.REACT_APP_BASE_URL +
                    "/1.0.0/manufacturer/logos/" +
                    filename +
                    "?token=" +
                    this.props.token.split(" ")[1]
                  }
                  alt="logo"
                  width="60px"
                />
              ) : (
                "Kein Logo"
              )}
            </Space>
          );
        },
      },
      {
        title: "Ändern",
        key: "modify",
        render: (text, record) => (
          <Space size="middle">
            <Button onClick={() => this.handleModifyManufacturer(record)}>
              Ändern
            </Button>
          </Space>
        ),
      },
      {
        title: "Löschen",
        key: "delete",
        render: (text, record) => (
          <Space size="middle">
            <Popconfirm
              title="Sind Sie sicher, dieses Hersteller zu löschen?"
              onConfirm={() => this.handleDeleteManufacturer(record)}
              okText="Ja"
              cancelText="Nein"
            >
              <Button>Löschen</Button>
            </Popconfirm>
          </Space>
        ),
      },
    ];
    return (
      <div>
        <Row>
          <Col span={12} style={{ padding: "1em" }}>
            <h1>Hersteller</h1>
            <Table
              columns={columns}
              dataSource={this.state.manufacturers}
              locale={{ emptyText: "Keine Daten" }}
              loading={!this.props.status && !this.state.loaded}
              pagination={{
                position: ["bottomCenter"],
                pageSize: 10,
              }}
            />
          </Col>
          <Col span={12} style={{ padding: "1em" }}>
            <h1>Neue Hersteller</h1>
            <Form
              ref={this.formRef}
              {...formItemLayout}
              name="project"
              onFinish={this.submitForm}
              scrollToFirstError
            >
              <Form.Item
                name="name"
                label="Name"
                rules={[
                  {
                    required: true,
                    message: "Bitte geben Sie den Herstellernamen ein!",
                    whitespace: true,
                  },
                ]}
              >
                <Input
                  name="name"
                  value={this.state.name}
                  onChange={this.handleChange}
                />
              </Form.Item>
              <Form.Item name="logo" label="Logo">
                <Dragger
                  name="file"
                  multiple={false}
                  //onChange={this.changeUploadedFile}
                  onRemove={this.removeFile}
                  accept=".png,.jpg,.jpeg,.gif"
                  beforeUpload={this.beforeUpload}
                  fileList={this.state.file}
                >
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">
                    Klicken oder ziehen Sie eine Datei in diesen Bereich, um sie
                    hochzuladen
                  </p>
                </Dragger>
              </Form.Item>
              <Form.Item>
                <Button
                  htmlType="submit"
                  type="primary"
                  style={{ margin: "0.5em" }}
                >
                  <SaveFilled />
                  Speichern
                </Button>
                {this.state.modify && (
                  <Button
                    htmlType="reset"
                    type="default"
                    onClick={this.handleCancelUpdate}
                    style={{ margin: "0.5em" }}
                  >
                    <CloseCircleFilled />
                    Stornieren
                  </Button>
                )}
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  status: state.manufacturerReducer.status,
  token: state.loginReducer.token,
  manufacturers: state.manufacturerReducer.manufacturers,
  saveStatus: state.manufacturerReducer.saveStatus,
  message: state.manufacturerReducer.message,
});

const Manufacturers = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectedManufacturers);
export default Manufacturers;
