import { INSTRUCTION_ACTIONS } from "../constants/action-types";
import axios from "axios";

export const getAllInstructions = () => {
  return async (dispatch) => {
    dispatch({ type: INSTRUCTION_ACTIONS.INSTRUCTIONS_LOADING });
    await axios
      .get("/1.0.0/instruction")
      .then((response) => {
        dispatch({
          type: INSTRUCTION_ACTIONS.INSTRUCTIONS_LOADED,
          payload: response.data,
        });
      })
      .catch((err) => {
        console.log(err.message);
        dispatch({
          type: INSTRUCTION_ACTIONS.INSTRUCTIONS_ERROR,
          payload: err.response.data,
        });
      });
  };
};

export const deleteInstruction = (instructionId) => {
  return async () => {
    await axios.delete("/1.0.0/instruction/" + instructionId).catch((err) => {
      console.log(err.message);
    });
  };
};

export const saveInstruction = (formData) => {
  return async (dispatch) => {
    dispatch({ type: INSTRUCTION_ACTIONS.INSTRUCTION_SAVE_START });
    await axios
      .post("/1.0.0/instruction", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then(() => {
        dispatch({ type: INSTRUCTION_ACTIONS.INSTRUCTION_SAVE_SUCCESS });
      })
      .catch((err) => {
        dispatch({
          type: INSTRUCTION_ACTIONS.INSTRUCTION_SAVE_ERROR,
          payload: err.response.data,
        });
      });
  };
};
