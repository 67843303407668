import { COMPANY_ACTIONS } from "../constants/action-types";

const initialState = {
  companies: [],
  message: {},
  status: false,
  saveStatus: false,
};

const companyReducer = (state = initialState, action) => {
  switch (action.type) {
    case COMPANY_ACTIONS.COMPANY_SAVE_START:
      return Object.assign({}, state, {
        saveStatus: false,
      });
    case COMPANY_ACTIONS.COMPANY_SAVE_SUCCESS:
      return Object.assign({}, state, {
        saveStatus: true,
      });
    case COMPANY_ACTIONS.COMPANY_SAVE_ERROR:
      return Object.assign({}, state, {
        saveStatus: false,
        message: action.payload,
      });
    case COMPANY_ACTIONS.COMPANY_LOADING:
      return Object.assign({}, state, {
        status: false,
        message: {},
        companies: [],
      });
    case COMPANY_ACTIONS.COMPANY_LOADED:
      return Object.assign({}, state, {
        status: true,
        message: {},
        companies: action.payload.companies,
      });
    case COMPANY_ACTIONS.COMPANY_ERROR:
      return Object.assign({}, state, {
        status: true,
        message: action.payload.message,
        companies: [],
      });

    default:
      return state;
  }
};

export default companyReducer;
