export const ITEM_TYPE_TEXT = 1,
  ITEM_TYPE_NUMERIC = 2,
  ITEM_TYPE_DATE = 3,
  ITEM_TYPE_DATETIME = 4,
  ITEM_TYPE_COMBO = 5,
  ITEM_TYPE_MULTISELECT = 6,
  ITEM_TYPE_IMAGE = 7;

export const itemTypes = [
  { typeId: 1, type: "Text" },
  { typeId: 2, type: "Numerisch" },
  { typeId: 3, type: "Datum" },
  { typeId: 4, type: "Barcode" },
  { typeId: 5, type: "Einzelauswahl" },
  { typeId: 6, type: "Mehrfachauswahl" },
  { typeId: 7, type: "Bild" },
];
