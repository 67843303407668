import Users from "../components/Users/users";
import Projects from "../components/Projects/projects";
import Manufacturers from "../components/Manufacturers/manufacturers";
import Instructions from "../components/Instructions/instructions";
import Certificates from "../components/Certificates/certificates";
import Forms from "../components/Projects/forms";
import Reports from "../components/Reports/reports";
import Blueprints from "../components/Blueprints/blueprints";
import ProjectDocuments from "../components/Project-documents/projectdocuments";
import Profile from "../components/Users/profile";
import Company from "../components/Company/company";

export const routes = [
  {
    path: "/fs/projekte",
    component: Projects,
    rights: [1, 2, 3],
  },
  {
    path: "/fs/forms",
    component: Forms,
    rights: [1, 2, 3],
  },
  {
    path: "/fs/rapporte/:id",
    component: Reports,
    rights: [1, 2, 3],
  },
  {
    path: "/fs/planunterlagen/:id",
    component: Blueprints,
    rights: [1, 2, 3],
  },
  {
    path: "/fs/projectdoku/:id",
    component: ProjectDocuments,
    rights: [1, 2, 3],
  },
  {
    path: "/fs/gesendeterapporte",
    component: Reports,
    rights: [1, 3],
  },
  {
    path: "/fs/benutzer",
    component: Users,
    rights: [1, 3],
  },
  {
    path: "/fs/hersteller",
    component: Manufacturers,
    rights: [1, 3],
  },
  {
    path: "/fs/montageanleitungen",
    component: Instructions,
    rights: [1, 3],
  },
  {
    path: "/fs/zertifikate",
    component: Certificates,
    rights: [1, 3],
  },
  {
    path: "/fs/profile",
    component: Profile,
    rights: [1, 2, 3],
  },
  {
    path: "/fs/company",
    component: Company,
    rights: [1, 3],
  },
];
