import React, { Component } from "react";
import Body from "./body";
import { Layout, Menu, Dropdown } from "antd";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UserOutlined,
  LogoutOutlined,
  ProjectOutlined,
  BarsOutlined,
  ToolOutlined,
  ReconciliationOutlined,
  ProfileOutlined,
  FormOutlined,
  BankOutlined,
} from "@ant-design/icons";
import "./main.scss";
import logo from "../../static/Logo-FireShield.png";
import { connect } from "react-redux";
import { logout } from "../../actions/login";
import { NavLink } from "react-router-dom";
import HttpService from "../../services/http";

const { Header, Sider, Content } = Layout;

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(logout()),
  };
};

class ConnectedMain extends Component {
  state = {
    collapsed: false,
    selectedKey: "1",
    width: 0,
  };

  constructor(props) {
    super(props);
    HttpService.setAxiosDefaults(props.token);
  }

  setWidth = () => {
    this.setState({ width: window.innerWidth });
  };

  componentDidMount = async () => {
    this.setDefaultSelectedKeyForMenu();
    this.setWidth();
    window.addEventListener("resize", this.setWidth);
  };

  componentWillUnmount = () => {
    HttpService.clearToken();
    window.removeEventListener("resize", this.setWidth);
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.setDefaultSelectedKeyForMenu();
    }
  }

  setDefaultSelectedKeyForMenu = () => {
    switch (this.props.location.pathname) {
      case "/fs/projekte":
        this.setState({ selectedKey: "1" });
        break;
      case "/fs/gesendeterapporte":
        this.setState({ selectedKey: "5" });
        break;
      case "/fs/hersteller":
        this.setState({ selectedKey: "6" });
        break;
      case "/fs/montageanleitungen":
        this.setState({ selectedKey: "7" });
        break;
      case "/fs/zertifikate":
        this.setState({ selectedKey: "8" });
        break;
      case "/fs/benutzer":
        this.setState({ selectedKey: "9" });
        break;
      case "/fs/forms":
        this.setState({ selectedKey: "10" });
        break;
      case "/fs/company":
        this.setState({ selectedKey: "11" });
        break;
      default:
        this.setState({ selectedKey: null });
        break;
    }
  };

  handleSelect = (event) => {
    this.setState({ selectedKey: event.key });
  };

  logout = async () => {
    await this.props.logout();
    this.props.history.push("/login");
  };

  handleMenuClick = async (event) => {
    switch (event.key) {
      case "logout":
        await this.logout();
        break;

      default:
        break;
    }
  };

  renderMenu = () => {
    return (
      <Menu onClick={this.handleMenuClick}>
        <Menu.Item key="profile" icon={<ProfileOutlined />}>
          <NavLink to="/fs/profile">Profile</NavLink>
        </Menu.Item>
        <Menu.Item key="logout" icon={<LogoutOutlined />}>
          Ausloggen
        </Menu.Item>
      </Menu>
    );
  };

  hasRight = (rights) => {
    if (rights.includes(this.props.user.groupId)) {
      return true;
    } else {
      return false;
    }
  };

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };
  render() {
    return (
      <div style={{ height: "100%" }}>
        <Layout style={{ height: "100vh" }}>
          <Sider
            trigger={null}
            collapsible
            collapsed={this.state.collapsed}
            className={!this.state.collapsed && "fireshield-sider"}
          >
            <div
              className={
                this.state.collapsed
                  ? "logo-container logo-container-collapsed"
                  : "logo-container"
              }
            >
              <img src={logo} alt="Fireshield logo" className="logo-icon" />
            </div>
            <Menu
              theme="dark"
              mode="inline"
              selectedKeys={[this.state.selectedKey]}
              onSelect={this.handleSelect}
            >
              <Menu.Item key="1" icon={<ProjectOutlined />}>
                <NavLink to="/fs/projekte" className="link-color">
                  Projekte
                </NavLink>
              </Menu.Item>
              {this.props.user.groupId === 1 && (
                <Menu.Item key="10" icon={<FormOutlined />}>
                  <NavLink to="/fs/forms" className="link-color">
                    Formulare
                  </NavLink>
                </Menu.Item>
              )}
              {
                /* this.props.user.groupId === 1 */ this.hasRight([1, 3]) && (
                  <Menu.Item key="5" icon={<BarsOutlined />}>
                    <NavLink to="/fs/gesendeterapporte" className="link-color">
                      Gesendete Rapporte
                    </NavLink>
                  </Menu.Item>
                )
              }
              {this.props.user.groupId === 1 && (
                <Menu.Item key="6" icon={<ToolOutlined />}>
                  <NavLink to="/fs/hersteller" className="link-color">
                    Hersteller
                  </NavLink>
                </Menu.Item>
              )}
              {this.props.user.groupId === 1 && (
                <Menu.Item key="7" icon={<ReconciliationOutlined />}>
                  <NavLink to="/fs/montageanleitungen" className="link-color">
                    Montageanleitungen
                  </NavLink>
                </Menu.Item>
              )}
              {this.props.user.groupId === 1 && (
                <Menu.Item key="8" icon={<ProfileOutlined />}>
                  <NavLink to="/fs/zertifikate" className="link-color">
                    Zertifikate
                  </NavLink>
                </Menu.Item>
              )}
              {
                /* this.props.user.groupId === 1 */ this.hasRight([1, 3]) && (
                  <Menu.Item key="9" icon={<UserOutlined />}>
                    <NavLink to="/fs/benutzer" className="link-color">
                      Benutzer
                    </NavLink>
                  </Menu.Item>
                )
              }
              {
                /* this.props.user.groupId === 1 */ this.hasRight([1]) && (
                  <Menu.Item key="11" icon={<BankOutlined />}>
                    <NavLink to="/fs/company" className="link-color">
                      Firmen
                    </NavLink>
                  </Menu.Item>
                )
              }
            </Menu>
          </Sider>
          <Layout className="site-layout">
            <Header
              className="site-layout-background"
              style={{ padding: "0 26px 0 26px" }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                {React.createElement(
                  this.state.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
                  {
                    className: "trigger",
                    onClick: this.toggle,
                  }
                )}
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div>
                    <strong style={{ color: "#000000" }}>
                      Wilkommen {this.props.user.firstname}{" "}
                      {this.props.user.lastname}
                      {this.props.user.companyId
                        ? " (" + this.props.user.company.name + ")"
                        : ""}
                    </strong>
                  </div>
                  <Dropdown.Button
                    size="large"
                    overlay={this.renderMenu}
                    icon={
                      this.props.user.filename !== null ? (
                        <img
                          src={
                            process.env.REACT_APP_BASE_URL +
                            "/1.0.0/users/profile/" +
                            this.props.user.filename +
                            "?token=" +
                            this.props.token.split(" ")[1]
                          }
                          alt="logo"
                          style={{
                            borderRadius: "35px",
                            objectFit: "cover",
                            objectPosition: "50% 0",
                            width: "30px",
                            height: "30px",
                            margin: "0 0 16px 0",
                          }}
                        />
                      ) : (
                        <UserOutlined />
                      )
                    }
                    style={
                      {
                        /* float: "right",
                    margin: "12px 26px 0 0", */
                        /* width: "60px !important", */
                      }
                    }
                  />
                </div>
              </div>
            </Header>
            <Content
              className="site-layout-background"
              style={{
                margin: "24px 16px",
                padding: 24,
                height: "100%",
                minHeight: this.state.width < 1450 ? "120%" : "0",
              }}
            >              
              <Body />
            </Content>
          </Layout>
        </Layout>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  token: state.loginReducer.token,
  user: state.loginReducer.user,
});

const Main = connect(mapStateToProps, mapDispatchToProps)(ConnectedMain);
export default Main;
