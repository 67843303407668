import { PROJECTDOCU_ACTIONS } from "../constants/action-types";
import axios from "axios";

export const getAllProjectDocuments = (projectId) => {
  return async (dispatch) => {
    dispatch({ type: PROJECTDOCU_ACTIONS.PROJECTDOCUS_LOADING });
    await axios
      .get("/1.0.0/projectdocu", { params: { projectId: projectId } })
      .then((response) => {
        dispatch({
          type: PROJECTDOCU_ACTIONS.PROJECTDOCUS_LOADED,
          payload: response.data,
        });
      })
      .catch((err) => {
        console.log(err.message);
        dispatch({
          type: PROJECTDOCU_ACTIONS.PROJECTDOCUS_ERROR,
          payload: err.response.data,
        });
      });
  };
};

export const deleteProjectDocument = (projectdocumentId) => {
  return async () => {
    await axios
      .delete("/1.0.0/projectdocu/" + projectdocumentId)
      .catch((err) => {
        console.log(err.message);
      });
  };
};

export const saveProjectDocument = (formData) => {
  return async (dispatch) => {
    dispatch({ type: PROJECTDOCU_ACTIONS.PROJECTDOCU_SAVE_START });
    await axios
      .post("/1.0.0/projectdocu", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then(() => {
        dispatch({ type: PROJECTDOCU_ACTIONS.PROJECTDOCU_SAVE_SUCCESS });
      })
      .catch((err) => {
        dispatch({
          type: PROJECTDOCU_ACTIONS.PROJECTDOCU_SAVE_ERROR,
          payload: err.response.data,
        });
      });
  };
};
