import React, { Component } from "react";
import { Button, Input, message, Form } from "antd";
import { UserOutlined } from "@ant-design/icons";
import logo from "../../static/Logo-FireShield.png";
import "./login.scss";
import axios from "axios";
import { NavLink } from "react-router-dom";

export default class ForgotPassword extends Component {
  formRef = React.createRef();

  state = {
    email: "",
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleFormSubmit = async () => {
    await axios
      .post("/1.0.0/users/forgot-password", {
        email: this.state.email,
      })
      .then((response) => {
        message.success("Passwort wurde zurückgesetzt", 2);
        this.props.history.push("/login");
      })
      .catch((err) => {
        message.error("Benutzer existiert nicht", 2);
        this.formRef.current.resetFields();
        this.setState({ email: "" });
      });
  };
  render() {
    return (
      <div>
        <div className="login-page-container">
          <Form
            name="normal_login"
            className="login-form"
            onFinish={this.handleFormSubmit}
            ref={this.formRef}
          >
            <div className="login-page-icon-container">
              <img
                src={logo}
                alt="Fireshield logo"
                className="login-page-icon"
              />
            </div>
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: "Bitte geben Sie Ihre E-Mail Adresse ein!",
                },
                { type: "email", message: "Die Eingabe ist ungültig Email!" },
              ]}
            >
              <Input
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder="E-mail"
                name="email"
                autoComplete="off"
                value={this.state.email || ""}
                onChange={this.handleChange}
              />
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
              >
                Passwort zurücksetzen
              </Button>
              <Button type="default" className="login-form-button">
                <NavLink to="/login">Zurück zur Anmeldung</NavLink>
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    );
  }
}
