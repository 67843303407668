import { INSTRUCTION_ACTIONS } from "../constants/action-types";

const initialState = {
  instructions: [],
  message: {},
  status: false,
  saveStatus: false,
};

const instructionReducer = (state = initialState, action) => {
  switch (action.type) {
    case INSTRUCTION_ACTIONS.INSTRUCTION_SAVE_START:
      return Object.assign({}, state, {
        saveStatus: false,
      });
    case INSTRUCTION_ACTIONS.INSTRUCTION_SAVE_SUCCESS:
      return Object.assign({}, state, {
        saveStatus: true,
      });
    case INSTRUCTION_ACTIONS.INSTRUCTION_SAVE_ERROR:
      return Object.assign({}, state, {
        saveStatus: false,
        message: action.payload,
      });
    case INSTRUCTION_ACTIONS.INSTRUCTIONS_LOADING:
      return Object.assign({}, state, {
        status: false,
        message: {},
        instructions: [],
      });
    case INSTRUCTION_ACTIONS.INSTRUCTIONS_LOADED:
      return Object.assign({}, state, {
        status: true,
        message: {},
        instructions: action.payload.instructions,
      });
    case INSTRUCTION_ACTIONS.INSTRUCTIONS_ERROR:
      return Object.assign({}, state, {
        status: true,
        message: action.payload.message,
        instructions: [],
      });

    default:
      return state;
  }
};

export default instructionReducer;
