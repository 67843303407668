import { FORMS_ACTIONS } from "../constants/action-types";
import axios from "axios";

export const createForm = (form) => {
  return async (dispatch) => {
    dispatch({ type: FORMS_ACTIONS.FORM_SAVE_START });
    await axios
      .post("/1.0.0/sheets/save-sheet", form, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then(() => {
        dispatch({ type: FORMS_ACTIONS.FORM_SAVE_SUCCESS });
      })
      .catch((err) => {
        dispatch({
          type: FORMS_ACTIONS.FORM_SAVE_ERROR,
          payload: err.response.data,
        });
      });
  };
};

export const modifyForm = (form) => {
  return async (dispatch) => {
    dispatch({ type: FORMS_ACTIONS.FORM_SAVE_START });
    await axios
      .post("/1.0.0/sheets/modify-sheet", form, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then(() => {
        dispatch({ type: FORMS_ACTIONS.FORM_SAVE_SUCCESS });
      })
      .catch((err) => {
        dispatch({
          type: FORMS_ACTIONS.FORM_SAVE_ERROR,
          payload: err.response.data,
        });
      });
  };
};

export const getAllForms = () => {
  return async (dispatch) => {
    dispatch({ type: FORMS_ACTIONS.FORMS_LOADING });
    await axios
      .get("/1.0.0/sheets", {
        params: { admin: true },
      })
      .then((response) => {
        dispatch({
          type: FORMS_ACTIONS.FORMS_LOADED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: FORMS_ACTIONS.FORMS_ERROR,
          payload: err.response.data,
        });
        console.log(err.message);
      });
  };
};

export const deleteForm = (formId) => {
  return async () => {
    await axios.delete("/1.0.0/sheets/" + formId).catch((err) => {
      console.log(err.message);
    });
  };
};

export const activateForm = (formId) => {
  return async () => {
    await axios.put("/1.0.0/sheets/" + formId).catch((err) => {
      console.log(err.message);
    });
  };
};
