import React, { Component } from "react";
import {
  Row,
  Col,
  Table,
  Form,
  Input,
  Button,
  Space,
  Select,
  message,
  Popconfirm,
} from "antd";
import { connect } from "react-redux";
import {
  getAllProjects,
  createProject,
  deleteProject,
} from "../../actions/project";
import { NavLink } from "react-router-dom";
import Highlighter from "react-highlight-words";
import {
  SearchOutlined,
  SaveFilled,
  CloseCircleFilled,
} from "@ant-design/icons";
import { getAllUsers } from "../../actions/users";
import { getAllReports } from "../../actions/reports";

const mapDispatchToProps = (dispatch) => {
  return {
    getAllProjects: (userId, token) => dispatch(getAllProjects(userId, token)),
    createProject: (project, token) => dispatch(createProject(project, token)),
    deleteProject: (projectId) => dispatch(deleteProject(projectId)),
    getAllUsers: (token, companyId) => dispatch(getAllUsers(token, companyId)),
    getAllReports: (projectId) => dispatch(getAllReports(projectId)),
  };
};

const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};

class ConnectedProjects extends Component {
  formRef = React.createRef();

  state = {
    projects: [],
    loaded: false,
    city: "",
    name: "",
    address: "",
    customer: "",
    sortedInfo: {},
    selectedUser: this.props.user.id,
    width: 0,
  };

  componentDidMount = async () => {
    await this.getAllProjects();
    if (this.props.user.groupId === 1) {
      await this.props.getAllUsers();
      this.formRef.current.setFieldsValue({ user: this.state.selectedUser });
    }
    if (this.props.user.groupId === 3) {
      await this.props.getAllUsers(undefined, this.props.user.companyId);
      this.formRef.current.setFieldsValue({ user: this.state.selectedUser });
    }
    this.updateWidth();
    window.addEventListener("resize", this.updateWidth);
  };

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.updateWidth);
  };

  updateWidth = () => {
    this.setState({ width: window.innerWidth });
  };

  getAllProjects = async () => {
    this.setState({ loaded: false });
    if (this.props.user.groupId === 1) {
      await this.props.getAllProjects(undefined);
      await this.props.getAllReports();
    } else if (this.props.user.groupId === 3) {
      await this.props.getAllProjects(undefined, this.props.user.companyId);
      await this.props.getAllReports(undefined, this.props.user.id);
    } else {
      await this.props.getAllProjects(this.props.user.id);
      await this.props.getAllReports(undefined, this.props.user.id);
    }
    if (this.props.status) {
      const projects = this.props.projects.map((project) => {
        return {
          ...project,
          key: project.id,
          userName:
            project.user === null
              ? "Kein Benutzer"
              : `${project.user.firstname} ${project.user.lastname}`,
          //countOfSheets: project.sheets.length,
          countOfBlueprints: project.blueprints.length,
          countofProjectDocuments: project.projectdocuments.length,
          countofReports: this.props.reports.filter(
            (report) => report.projectId === project.id
          ).length,
        };
      });
      this.setState({ projects: projects, loaded: true });
    }
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  submitForm = async () => {
    const project = {
      id: this.state.selectedId !== null ? this.state.selectedId : undefined,
      name: this.state.name,
      city: this.state.city,
      address: this.state.address,
      customer: this.state.customer,
      userId:
        this.props.user.groupId === 1
          ? this.state.selectedUser
          : this.props.user.id,
    };
    await this.props.createProject(project, this.props.token);
    if (this.props.saveStatus) {
      this.setState({
        city: "",
        name: "",
        address: "",
        customer: "",
        selectedId: null,
        userId: this.props.user.id,
        modify: false,
      });
      this.formRef.current.resetFields();
      message.success("Erfolgreich speichern!", 2);
      await this.getAllProjects();
    } else {
      if (this.props.message.code === 1005) {
        message.error("Projekt mit diesem Namen existiert bereits!", 2);
      } else {
        message.error("Speichern erfolglos!", 2);
      }
    }
  };

  handleDeleteProject = async (project) => {
    await this.props.deleteProject(project.id);
    await this.getAllProjects();
  };

  getColumnSearchProps = (dataIndex, searchLabel) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Suche ${searchLabel}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Suche
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 100 }}
          >
            Zurücksetzen
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text, record) =>
      this.state.searchedColumn === dataIndex ? (
        <Space align="center">
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[this.state.searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        </Space>
      ) : (
        <Space align="center">{text}</Space>
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  handleModify = (record) => {
    this.setState({
      city: record.city,
      name: record.name,
      address: record.address,
      customer: record.customer,
      selectedId: record.id,
      selectedUser: record.userId,
      modify: true,
    });
    this.formRef.current.setFieldsValue({
      city: record.city,
      name: record.name,
      address: record.address,
      customer: record.customer,
      user: record.userId,
    });
  };

  handleCancelUpdate = () => {
    this.setState({
      city: "",
      name: "",
      address: "",
      customer: "",
      selectedId: null,
      userId: this.props.user.id,
      modify: false,
    });
    this.formRef.current.resetFields();
  };

  handleChangeTable = (pagination, filters, sorter) => {
    this.setState({
      sortedInfo: sorter,
    });
  };

  handleChangeUser = (event) => {
    this.setState({ selectedUser: event });
  };

  render() {
    let { sortedInfo } = this.state;
    sortedInfo = sortedInfo || {};

    const columns = [
      {
        title: "Projekt",
        dataIndex: "name",
        key: "name",
        width: "50%",
        sorter: (a, b) => a.name.localeCompare(b.name),
        sortOrder: sortedInfo.columnKey === "name" && sortedInfo.order,
        responsive: ["sm"],
        ...this.getColumnSearchProps("name", "name"),
      },
      {
        title: "Strasse",
        dataIndex: "address",
        key: "address",
        width: "50%",
        sorter: (a, b) => a.address.localeCompare(b.address),
        sortOrder: sortedInfo.columnKey === "address" && sortedInfo.order,
        responsive: ["sm"],
        ...this.getColumnSearchProps("address", "Strasse"),
      },
      {
        title: "Kunde",
        dataIndex: "customer",
        key: "customer",
        width: "50%",
        sorter: (a, b) => a.customer.localeCompare(b.customer),
        sortOrder: sortedInfo.columnKey === "customer" && sortedInfo.order,
        responsive: ["sm"],
        ...this.getColumnSearchProps("customer", "Kunde"),
      },
      {
        title: "Stadt",
        dataIndex: "city",
        key: "city",
        width: "50%",
        sorter: (a, b) => a.city.localeCompare(b.city),
        sortOrder: sortedInfo.columnKey === "city" && sortedInfo.order,
        responsive: ["sm"],
        ...this.getColumnSearchProps("city", "Stadt"),
      },
      this.props.user.groupId === 1
        ? {
            title: "Benutzer",
            dataIndex: "userName",
            key: "userName",
            width: "50%",
            sorter: (a, b) => a.userName.localeCompare(b.userName),
            sortOrder: sortedInfo.columnKey === "userName" && sortedInfo.order,
            responsive: ["sm"],
            ...this.getColumnSearchProps("userName", "Benutzer"),
          }
        : {},
      this.props.user.groupId === 1
        ? {
            title: "Firma",
            /*  dataIndex: "userName", */
            key: "company",
            width: "50%",
            sorter: (a, b) => {
              const aName = a.companyId ? a.company.name : "Kein Firma";
              const bName = b.companyId ? b.company.name : "Kein Firma";
              return aName.localeCompare(bName);
            },
            sortOrder: sortedInfo.columnKey === "company" && sortedInfo.order,
            responsive: ["sm"],
            //...this.getColumnSearchProps("userName", "Benutzer"),
            render: (string, data) => {
              return (
                <Space size="middle">
                  {data.companyId ? data.company.name : "Kein Firma"}
                </Space>
              );
            },
          }
        : {},
      {
        title: "Ändern",
        key: "modify",
        //width: 100,
        responsive: ["sm"],
        render: (text, record) => (
          <Space size="middle">
            <Button onClick={() => this.handleModify(record)}>Ändern</Button>
          </Space>
        ),
      },
      {
        title: "Löschen",
        key: "delete",
        //width: 100,
        responsive: ["sm"],
        render: (text, record) => (
          <Space size="middle">
            <Popconfirm
              title="Sind Sie sicher, dieses Projekt zu löschen?"
              onConfirm={() => this.handleDeleteProject(record)}
              okText="Ja"
              cancelText="Nein"
            >
              <Button>Löschen</Button>
            </Popconfirm>
          </Space>
        ),
      },
    ];
    return (
      <div>
        <Row>
          <Col
            span={this.state.width < 1450 ? 24 : 16}
            style={{ padding: "1em" }}
          >
            <h1>Projekte</h1>
            <Table
              columns={columns}
              dataSource={this.state.projects}
              locale={{ emptyText: "Keine Daten" }}
              loading={!this.props.status && !this.state.loaded}
              pagination={{
                position: ["bottomCenter"],
                pageSize: 10,
              }}
              size="small"
              onChange={this.handleChangeTable}
              expandable={{
                expandedRowRender: (record) => {
                  return (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                      }}
                    >
                      {/* <div>
                        <NavLink to={"/fs/forms/" + record.id}>
                          <Button type="primary">
                            Formularen ({record.countOfSheets})
                          </Button>
                        </NavLink>
                      </div> */}
                      <div>
                        <NavLink to={"/fs/rapporte/" + record.id}>
                          <Button type="primary">
                            Rapporte ({record.countofReports})
                          </Button>
                        </NavLink>
                      </div>
                      <div>
                        <NavLink to={"/fs/planunterlagen/" + record.id}>
                          <Button type="primary">
                            Planunterlagen ({record.countOfBlueprints})
                          </Button>
                        </NavLink>
                      </div>
                      <div>
                        <NavLink to={"/fs/projectdoku/" + record.id}>
                          <Button type="primary">
                            Projektdokumentation (
                            {record.countofProjectDocuments})
                          </Button>
                        </NavLink>
                      </div>
                    </div>
                  );
                },
                rowExpandable: () => true,
              }}
            />
          </Col>
          <Col span={8} style={{ padding: "1em" }}>
            <h1>Neues Projekt:</h1>
            <Form
              ref={this.formRef}
              {...formItemLayout}
              name="project"
              onFinish={this.submitForm}
              scrollToFirstError
            >
              <Form.Item
                name="name"
                label="Projektname"
                rules={[
                  {
                    required: true,
                    message: "Bitte geben Sie den Projektnamen ein!",
                    whitespace: true,
                  },
                ]}
              >
                <Input
                  name="name"
                  value={this.state.name}
                  onChange={this.handleChange}
                />
              </Form.Item>
              <Form.Item
                name="address"
                label="Strasse"
                rules={[
                  {
                    required: true,
                    message: "Bitte geben Sie die Strasse ein!",
                    whitespace: true,
                  },
                ]}
              >
                <Input
                  name="address"
                  value={this.state.address}
                  onChange={this.handleChange}
                />
              </Form.Item>
              <Form.Item
                name="customer"
                label="Kunde"
                rules={[
                  {
                    required: true,
                    message: "Bitte geben Sie den Kunde ein!",
                    whitespace: true,
                  },
                ]}
              >
                <Input
                  name="customer"
                  value={this.state.customer}
                  onChange={this.handleChange}
                />
              </Form.Item>
              <Form.Item
                name="city"
                label="Stadt"
                rules={[
                  {
                    required: true,
                    message: "Bitte geben Sie die Stadt ein!",
                    whitespace: true,
                  },
                ]}
              >
                <Input
                  name="city"
                  value={this.state.city}
                  onChange={this.handleChange}
                />
              </Form.Item>
              {(this.props.user.groupId === 1 ||
                this.props.user.groupId === 3) && (
                <Form.Item
                  name="user"
                  label="Benutzer"
                  rules={[
                    {
                      required: this.props.user.groupId === 1 ? true : false,
                      message: "Bitte geben Sie den Hersteller ein!",
                    },
                  ]}
                >
                  <Select
                    name="user"
                    value={this.state.selectedUser}
                    onChange={this.handleChangeUser}
                  >
                    {this.props.users.map((user) => {
                      return (
                        <Option value={user.id} key={user.id}>
                          {`${user.firstname} ${user.lastname} (${
                            user.companyId ? user.company.name : "Kein Firma"
                          })`}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              )}
              <Form.Item>
                <Button
                  htmlType="submit"
                  type="primary"
                  style={{ margin: "0.5em" }}
                >
                  <SaveFilled />
                  Speichern
                </Button>
                {this.state.modify && (
                  <Button
                    htmlType="reset"
                    type="default"
                    onClick={this.handleCancelUpdate}
                    style={{ margin: "0.5em" }}
                  >
                    <CloseCircleFilled />
                    Stornieren
                  </Button>
                )}
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  token: state.loginReducer.token,
  user: state.loginReducer.user,
  projects: state.projectReducer.projects,
  status: state.projectReducer.status,
  saveStatus: state.projectReducer.saveStatus,
  users: state.usersReducer.users,
  reports: state.reportsReducer.reports,
  message: state.projectReducer.message,
});

const Projects = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectedProjects);
export default Projects;
