import React, { Component } from "react";
import {
  Button,
  Row,
  Col,
  Input,
  Form,
  Modal,
  Checkbox,
  message,
  Upload,
  Select,
} from "antd";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { v4 as uuidv4 } from "uuid";
import { itemTypes } from "../../constants/sheetItem-types";
import {
  DeleteOutlined,
  MinusCircleOutlined,
  PlusOutlined,
  SaveFilled,
  CloseCircleFilled,
  UploadOutlined,
} from "@ant-design/icons";
import { connect } from "react-redux";
import { createForm, modifyForm } from "../../actions/forms";

const mapDispatchToProps = (dispatch) => {
  return {
    createForm: (form) => dispatch(createForm(form)),
    modifyForm: (form) => dispatch(modifyForm(form)),
  };
};

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

/**
 * Moves an item from one list to another list.
 */

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,
  /* display: "flex",
  justifyContent: "space-evenly", */
  // change background colour if dragging
  background: isDragging ? "lightgreen" : "grey",

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? "lightblue" : "lightgrey",
  padding: grid,
  width: 650,
});

const formItemLayoutWithOutLabel = {
  wrapperCol: {
    xs: { span: 20, offset: 0 },
    sm: { span: 24, offset: 0 },
  },
};

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16 },
};

class ConnectedFormEditor extends Component {
  _mounted = false;

  formRef = React.createRef();

  state = {
    projectId: null,
    items: itemTypes.map((type) => {
      return {
        id: uuidv4(),
        type: type.type,
        typeId: type.typeId,
        name: "",
        hint: "",
        default: "",
        exportStart: "",
        exportDirection: "",
      };
    }),
    selected: [],
    name: "",
    description: "",
    itemIdsToDelete: [],
    fileList: [],
  };

  componentDidMount() {
    this._mounted = true;
    this.setState({ projectId: this.props.projectId });
    if (this.props.modify) {
      const sortedItems = this.props.selectedForm.items.sort((itema, itemb) => {
        return itema.index - itemb.index;
      });
      if (
        this.props.selectedForm &&
        this.props.selectedForm.reports.length > 0
      ) {
        this.setState({
          name: this.props.selectedForm.name + "_" + new Date().toISOString(),
        });
      } else {
        this.setState({
          name: this.props.selectedForm.name,
        });
      }
      this.setState({
        //name: this.props.selectedForm.name + "_" + new Date().toISOString(),
        description: this.props.selectedForm.description,
        selected: sortedItems.map((item) => {
          const uuid = uuidv4();
          this[`${uuid}_ref`] = React.createRef();
          return {
            id: uuid,
            savedId: item.id,
            default: item.itemDefaultValue,
            hint: item.itemHint,
            name: item.itemLabel,
            typeId: item.itemType,
            mandatory: item.mandatory,
            exportStart: item.exportStart,
            exportDirection: parseInt(item.exportDirection),
            type: itemTypes.find((type) => type.typeId === item.itemType).type,
            options:
              item.elements !== undefined
                ? item.elements.map((element) => {
                    return element.elementValue;
                  })
                : undefined,
          };
        }),
      });
    }
  }

  id2List = {
    droppable: "items",
    droppable2: "selected",
  };

  move = (source, destination, droppableSource, droppableDestination) => {
    if (droppableDestination.droppableId === "droppable2") {
      const sourceClone = Array.from(source);
      const destClone = Array.from(destination);
      const [removed] = sourceClone.splice(droppableSource.index, 1);
      destClone.splice(droppableDestination.index, 0, removed);

      const result = {}; //Array.from(source); //lista teljes újragenerálása uuid-val
      result[droppableSource.droppableId] = source.map((elem) => {
        return {
          id: uuidv4(),
          type: elem.type,
          typeId: elem.typeId,
          name: "",
          hint: "",
          default: "",
          exportStart: "",
          exportDirection: "",
        };
      });
      result[droppableDestination.droppableId] = destClone;

      return result;
    } else {
      const result = {};
      result[droppableSource.droppableId] = Array.from(source);
      result[droppableDestination.droppableId] = Array.from(destination);

      return result;
    }
  };

  getList = (id) => this.state[this.id2List[id]];

  onDragEnd = (result) => {
    const { source, destination, draggableId } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      const items = reorder(
        this.getList(source.droppableId),
        source.index,
        destination.index
      );

      let state = { items };

      if (source.droppableId === "droppable2") {
        state = { selected: items };
      }

      this.setState(state);
    } else {
      this[`${draggableId}_ref`] = React.createRef();
      const result = this.move(
        this.getList(source.droppableId),
        this.getList(destination.droppableId),
        source,
        destination
      );

      this.setState({
        items: result.droppable,
        selected: result.droppable2,
      });
    }
  };

  handleDelete = (index) => {
    const selected = this.state.selected;
    const toDelete = selected[index];
    if (toDelete.savedId !== undefined) {
      this.setState({
        itemIdsToDelete: [...this.state.itemIdsToDelete, toDelete.savedId],
      });
    }
    selected.splice(index, 1);
    this.setState({ selected: selected });
  };

  handleChangeName = (event, index) => {
    const selected = this.state.selected;
    selected[index].name = event.target.value;
    this.setState({ selected: selected });
  };
  handleChangeHint = (event, index) => {
    const selected = this.state.selected;
    selected[index].hint = event.target.value;
    this.setState({ selected: selected });
  };
  handleChangeDefaultValue = (event, index) => {
    const selected = this.state.selected;
    selected[index].default = event.target.value;
    this.setState({ selected: selected });
  };
  handleChangeStartExport = (event, index) => {
    const selected = this.state.selected;
    selected[index].exportStart = event.target.value;
    this.setState({ selected: selected });
  };
  handleChangeExportDirection = (event, index) => {
    const selected = this.state.selected;
    selected[index].exportDirection = event /* .target.value */;
    this.setState({ selected: selected });
  };

  handleChangeMandatory = (event, index) => {
    const selected = this.state.selected;
    selected[index].mandatory = !selected[index].mandatory;
    this.setState({ selected: selected });
  };

  handleFormChange = (selectedIndex, id) => {
    const selected = this.state.selected;
    selected[selectedIndex].options =
      this[`${id}_ref`].current.getFieldsValue().options;
    this.setState({ selected: selected });
  };

  setFormToDefault = () => {
    this.setState({ selected: [], fileList: [] });
    this.formRef.current.resetFields();
  };

  handleCancel = () => {
    this.setFormToDefault();
    this.props.cancel();
  };
  handleCloseAfterSave = async () => {
    this.setFormToDefault();
    await this.props.save();
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleSubmit = async () => {
    if (
      this.props.modify &&
      this.props.selectedForm &&
      this.props.selectedForm.reports.length === 0
    ) {
      await this.handleModify();
    } else {
      await this.handleSave();
    }
  };

  handleSave = async () => {
    const sheetToSave = {
      projectId: this.props.projectId,
      name: this.state.name,
      description: this.state.description,
      items: this.state.selected.map((item, index) => {
        return {
          index: index,
          itemType: item.typeId,
          mandatory: item.mandatory === undefined ? false : item.mandatory,
          itemLabel: item.name,
          itemHint: item.hint,
          itemDefaultValue: item.default,
          exportStart: item.exportStart,
          exportDirection: parseInt(item.exportDirection),
          elements:
            item.options !== undefined
              ? item.options.map((option, index) => {
                  return {
                    index: index,
                    elementName: option,
                    elementValue: option,
                  };
                })
              : null,
        };
      }),
    };
    const sheetToSaveFormData = new FormData();
    sheetToSaveFormData.set("data", JSON.stringify(sheetToSave));
    if (this.state.fileList.length > 0) {
      sheetToSaveFormData.append("file", this.state.fileList[0]);
    }
    await this.props.createForm(sheetToSaveFormData);
    if (this.props.saveStatus) {
      this.handleCloseAfterSave();
      message.success("Erfolgreich speichern!", 2);
    } else {
      if (this.props.message.code === 1101) {
        message.error("Formular enthält keine Elemente!", 2);
      } else if (this.props.message.code === 1102) {
        message.error("Formular enthält keine Optionen!", 2);
      } else {
        message.error("Speichern erfolglos!", 2);
      }
    }
  };

  handleModify = async () => {
    const sheetToSave = {
      sheetId: this.props.selectedForm.id,
      projectId: this.props.projectId,
      name: this.state.name,
      description: this.state.description,
      items: this.state.selected.map((item, index) => {
        return {
          id: item.savedId,
          index: index,
          itemType: item.typeId,
          mandatory: item.mandatory === undefined ? false : item.mandatory,
          itemLabel: item.name,
          itemHint: item.hint,
          itemDefaultValue: item.default,
          exportStart: item.exportStart,
          exportDirection: parseInt(item.exportDirection),
          elements:
            item.options !== undefined
              ? item.options.map((option, index) => {
                  return {
                    index: index,
                    elementName: option,
                    elementValue: option,
                  };
                })
              : null,
        };
      }),
      idsToDelete: this.state.itemIdsToDelete,
    };
    const sheetToSaveFormData = new FormData();
    sheetToSaveFormData.set("data", JSON.stringify(sheetToSave));
    if (this.state.fileList.length > 0) {
      sheetToSaveFormData.append("file", this.state.fileList[0]);
    }
    await this.props.modifyForm(sheetToSaveFormData);
    if (this.props.saveStatus) {
      this.handleCloseAfterSave();
    }
  };

  setDefaultFields = () => {
    if (this._mounted === true && this.props.modify) {
      if (
        this.props.selectedForm &&
        this.props.selectedForm.reports.length > 0
      ) {
        this.formRef.current.setFieldsValue({
          name: this.props.selectedForm.name + "_" + new Date().toISOString(),
          description: this.props.selectedForm.description,
        });
      } else {
        this.formRef.current.setFieldsValue({
          name: this.props.selectedForm.name,
          description: this.props.selectedForm.description,
        });
      }
    }
  };

  componentWillUnmount() {
    this._mounted = false;
  }

  removeFile = () => {
    this.setState({ fileList: [] });
  };

  beforeUpload = (file) => {
    this.setState({ fileList: [file] });
    return false;
  };

  render() {
    return (
      <div>
        <Modal
          width={1300}
          centered={true}
          visible={this.props.visible}
          title={this.props.modify ? "Formular ändern" : "Neue Formular"}
          onOk={this.handleSubmit}
          closable={true}
          onCancel={this.handleCancel}
          maskClosable={false}
          footer={[
            <Button key="back" onClick={this.handleCancel}>
              <CloseCircleFilled />
              Stornieren
            </Button>,
            <Button
              key="submit"
              type="primary"
              //loading={loading}
              onClick={this.handleSubmit}
            >
              <SaveFilled />
              Speichern
            </Button>,
          ]}
        >
          <div style={{ height: "550px", overflowY: "auto" }}>
            <Form
              {...layout}
              ref={(ref) => {
                if (this._mounted === true) {
                  this.formRef.current = ref;
                  this.setDefaultFields();
                  console.log(this.refs["formRef"]);
                  this._mounted = false;
                }
              }}
              name="control-ref"
              //onFinish={this.onFinish}
            >
              <Row>
                <Col span={8}>
                  <Form.Item
                    name="name"
                    label="Name"
                    rules={[{ required: true }]}
                  >
                    <Input
                      name="name"
                      value={this.state.name}
                      onChange={this.handleChange}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="description"
                    label="Beschreibung"
                    rules={[{ required: true }]}
                  >
                    <Input
                      name="description"
                      value={this.state.description}
                      onChange={this.handleChange}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="exportFile"
                    label="Export file"
                    rules={[{ required: false }]}
                  >
                    <Upload
                      fileList={this.state.fileList}
                      multiple={false}
                      //onChange={this.changeUploadedFile}
                      onRemove={this.removeFile}
                      accept=".xls,.xlsx"
                      beforeUpload={this.beforeUpload}
                    >
                      <Button icon={<UploadOutlined />}>Hochladen</Button>
                    </Upload>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
            <Row>
              <Col
                span={24}
                style={{
                  padding: "1em",
                  display: "flex",
                  justifyContent: "space-around",
                }}
              >
                <DragDropContext onDragEnd={this.onDragEnd}>
                  <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        style={getListStyle(snapshot.isDraggingOver)}
                      >
                        {this.state.items.map((item, index) => (
                          <Draggable
                            key={item.id}
                            draggableId={String(item.id)}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={getItemStyle(
                                  snapshot.isDragging,
                                  provided.draggableProps.style
                                )}
                              >
                                {item.type}
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                  <Droppable droppableId="droppable2">
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        style={getListStyle(snapshot.isDraggingOver)}
                      >
                        {this.state.selected.map((item, selectedIndex) => (
                          <Draggable
                            key={item.id}
                            draggableId={String(item.id)}
                            index={selectedIndex}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={getItemStyle(
                                  snapshot.isDragging,
                                  provided.draggableProps.style
                                )}
                              >
                                <div
                                  style={{
                                    padding: "0.5em",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {item.type}
                                </div>
                                <div>
                                  <div
                                    style={{
                                      padding: "0.5em",
                                    }}
                                  >
                                    Name:
                                  </div>
                                  <Input
                                    style={{ width: "70%", margin: "0.5em" }}
                                    value={item.name}
                                    placeholder="Name"
                                    onChange={(event) =>
                                      this.handleChangeName(
                                        event,
                                        selectedIndex
                                      )
                                    }
                                  />
                                </div>
                                {(item.typeId === 1 ||
                                  item.typeId === 2 ||
                                  item.typeId === 3) && (
                                  <div>
                                    <div
                                      style={{
                                        padding: "0.5em",
                                      }}
                                    >
                                      Standardwert:
                                    </div>
                                    <Input
                                      style={{
                                        width: "70%",
                                        margin: "0.5em",
                                      }}
                                      type={
                                        item.typeId === 2
                                          ? "number"
                                          : item.typeId === 3
                                          ? "date"
                                          : ""
                                      }
                                      value={item.default}
                                      placeholder="Standardwert"
                                      onChange={(event) =>
                                        this.handleChangeDefaultValue(
                                          event,
                                          selectedIndex
                                        )
                                      }
                                    />
                                  </div>
                                )}
                                <div>
                                  <div
                                    style={{
                                      padding: "0.5em",
                                    }}
                                  >
                                    Hinweis:
                                  </div>
                                  <Input
                                    style={{ width: "70%", margin: "0.5em" }}
                                    value={item.hint}
                                    placeholder="Hinweis"
                                    onChange={(event) =>
                                      this.handleChangeHint(
                                        event,
                                        selectedIndex
                                      )
                                    }
                                  />
                                </div>

                                <div>
                                  {(item.typeId === 5 || item.typeId === 6) && (
                                    <Form
                                      ref={(ref) => {
                                        this[`${item.id}_ref`].current = ref;
                                        if (
                                          this._mounted === true &&
                                          this.props.modify === true
                                        ) {
                                          this.formRef.current.setFieldsValue({
                                            options: item.options,
                                          });
                                        }
                                      }}
                                      name="dynamic_form_item"
                                      {...formItemLayoutWithOutLabel}
                                      //onFinish={onFinish}
                                      /* onChange={() =>
                                        this.handleFormChange(
                                          selectedIndex,
                                          item.id                                          
                                        )
                                      } */
                                    >
                                      <div
                                        style={{
                                          padding: "0.5em",
                                        }}
                                      >
                                        Optionen:
                                      </div>
                                      <Form.List
                                        name="options"
                                        style={{
                                          padding: "0.5em",
                                        }}
                                      >
                                        {(fields, { add, remove }) => {
                                          if (
                                            item.options !== undefined &&
                                            this._mounted === true
                                          ) {
                                            item.options.forEach((option) => {
                                              add(option);
                                            });
                                            fields = item.options;
                                          }
                                          return (
                                            <div style={{ padding: "0.5em" }}>
                                              {fields.map((field, index) => (
                                                <Form.Item
                                                  {...formItemLayoutWithOutLabel}
                                                  required={false}
                                                  key={field.key}
                                                  initialValue={fields}
                                                  label={"Option"}
                                                >
                                                  <Form.Item
                                                    {...field}
                                                    validateTrigger={[
                                                      "onChange",
                                                      "onBlur",
                                                    ]}
                                                    /* rules={[
                                                      {
                                                        required: true,
                                                        whitespace: true,
                                                        message: "",
                                                      },
                                                    ]} */
                                                    noStyle
                                                  >
                                                    <Input
                                                      placeholder="Option"
                                                      style={{
                                                        width: "60%",
                                                      }}
                                                      type="text"
                                                      onChange={() => {
                                                        this.handleFormChange(
                                                          selectedIndex,
                                                          item.id
                                                        );
                                                      }}
                                                      value={field}
                                                    />
                                                  </Form.Item>
                                                  {fields.length > 1 ? (
                                                    <MinusCircleOutlined
                                                      className="dynamic-delete-button"
                                                      style={{
                                                        margin: "0 8px",
                                                      }}
                                                      onClick={() => {
                                                        remove(field.name);
                                                      }}
                                                    />
                                                  ) : null}
                                                </Form.Item>
                                              ))}
                                              <Form.Item>
                                                <Button
                                                  onClick={() => {
                                                    add();
                                                  }}
                                                >
                                                  Neue Option
                                                  <PlusOutlined />
                                                </Button>
                                              </Form.Item>
                                            </div>
                                          );
                                        }}
                                      </Form.List>
                                    </Form>
                                  )}
                                </div>
                                <div
                                  style={{ display: "flex", padding: "0.5em" }}
                                >
                                  <div>Erforderlich:</div>
                                  <Checkbox
                                    style={{ margin: "0 0.5em 0.5em 0.5em" }}
                                    checked={item.mandatory}
                                    onChange={(event) =>
                                      this.handleChangeMandatory(
                                        event,
                                        selectedIndex
                                      )
                                    }
                                  />
                                </div>
                                <div>
                                  <div
                                    style={{
                                      padding: "0.5em",
                                    }}
                                  >
                                    Export Start Zelle:
                                  </div>
                                  <Input
                                    style={{ width: "70%", margin: "0.5em" }}
                                    value={item.exportStart}
                                    placeholder="Start Zelle"
                                    onChange={(event) =>
                                      this.handleChangeStartExport(
                                        event,
                                        selectedIndex
                                      )
                                    }
                                  />
                                </div>
                                <div>
                                  <div
                                    style={{
                                      padding: "0.5em",
                                    }}
                                  >
                                    Export Richtung:
                                  </div>
                                  <Select
                                    style={{ width: "70%", margin: "0.5em" }}
                                    value={item.exportDirection}
                                    placeholder="Richtung"
                                    onChange={(event) =>
                                      this.handleChangeExportDirection(
                                        event,
                                        selectedIndex
                                      )
                                    }
                                  >
                                    <Select.Option value={1}>
                                      Vertikal
                                    </Select.Option>
                                    <Select.Option value={2}>
                                      Horizontal
                                    </Select.Option>
                                  </Select>
                                </div>
                                <div style={{ padding: "0.5em" }}>
                                  <Button
                                    onClick={() =>
                                      this.handleDelete(selectedIndex)
                                    }
                                  >
                                    <DeleteOutlined />
                                  </Button>
                                </div>
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </Col>
            </Row>
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  status: state.formsReducer.status,
  saveStatus: state.formsReducer.saveStatus,
  message: state.formsReducer.message,
});

const FormEditor = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectedFormEditor);

export default FormEditor;
