import { BLUEPRINT_ACTIONS } from "../constants/action-types";
import axios from "axios";

export const getAllBlueprints = (projectId) => {
  return async (dispatch) => {
    dispatch({ type: BLUEPRINT_ACTIONS.BLUEPRINTS_LOADING });
    await axios
      .get("/1.0.0/blueprint", { params: { projectId: projectId } })
      .then((response) => {
        dispatch({
          type: BLUEPRINT_ACTIONS.BLUEPRINTS_LOADED,
          payload: response.data,
        });
      })
      .catch((err) => {
        console.log(err.message);
        dispatch({
          type: BLUEPRINT_ACTIONS.BLUEPRINTS_ERROR,
          payload: err.response.data,
        });
      });
  };
};

export const deleteBlueprint = (blueprintId) => {
  return async () => {
    await axios.delete("/1.0.0/blueprint/" + blueprintId).catch((err) => {
      console.log(err.message);
    });
  };
};

export const saveBlueprint = (formData) => {
  return async (dispatch) => {
    dispatch({ type: BLUEPRINT_ACTIONS.BLUEPRINT_SAVE_START });
    await axios
      .post("/1.0.0/blueprint", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then(() => {
        dispatch({ type: BLUEPRINT_ACTIONS.BLUEPRINT_SAVE_SUCCESS });
      })
      .catch((err) => {
        dispatch({
          type: BLUEPRINT_ACTIONS.BLUEPRINT_SAVE_ERROR,
          payload: err.response.data,
        });
      });
  };
};
