import { PROJECT_ACTIONS } from "../constants/action-types";

const initialState = {
  projects: [],
  message: {},
  status: false,
  saveStatus: false,
};

const projectReducer = (state = initialState, action) => {
  switch (action.type) {
    case PROJECT_ACTIONS.PROJECTS_LOADING:
      return Object.assign({}, state, {
        projects: [],
        message: "",
        status: false,
      });
    case PROJECT_ACTIONS.PROJECTS_LOADED:
      return Object.assign({}, state, {
        projects: action.payload.projects,
        message: "",
        status: true,
      });
    case PROJECT_ACTIONS.PROJECTS_ERROR:
      return Object.assign({}, state, {
        projects: [],
        message: action.payload.message,
        status: false,
      });
    case PROJECT_ACTIONS.PROJECT_SAVE_START:
      return Object.assign({}, state, {
        saveStatus: false,
      });
    case PROJECT_ACTIONS.PROJECT_SAVE_SUCCESS:
      return Object.assign({}, state, {
        saveStatus: true,
      });
    case PROJECT_ACTIONS.PROJECT_SAVE_ERROR:
      return Object.assign({}, state, {
        saveStatus: false,
        message: action.payload,
      });

    default:
      return state;
  }
};

export default projectReducer;
