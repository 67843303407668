import { BLUEPRINT_ACTIONS } from "../constants/action-types";

const initialState = {
  blueprints: [],
  message: {},
  status: false,
  saveStatus: false,
};

const blueprintReducer = (state = initialState, action) => {
  switch (action.type) {
    case BLUEPRINT_ACTIONS.BLUEPRINT_SAVE_START:
      return Object.assign({}, state, {
        saveStatus: false,
      });
    case BLUEPRINT_ACTIONS.BLUEPRINT_SAVE_SUCCESS:
      return Object.assign({}, state, {
        saveStatus: true,
      });
    case BLUEPRINT_ACTIONS.BLUEPRINT_SAVE_ERROR:
      return Object.assign({}, state, {
        saveStatus: false,
        message: action.payload,
      });
    case BLUEPRINT_ACTIONS.BLUEPRINTS_LOADING:
      return Object.assign({}, state, {
        status: false,
        message: {},
        blueprints: [],
      });
    case BLUEPRINT_ACTIONS.BLUEPRINTS_LOADED:
      return Object.assign({}, state, {
        status: true,
        message: {},
        blueprints: action.payload.blueprints,
      });
    case BLUEPRINT_ACTIONS.BLUEPRINTS_ERROR:
      return Object.assign({}, state, {
        status: true,
        message: action.payload.message,
        blueprints: [],
      });

    default:
      return state;
  }
};

export default blueprintReducer;
