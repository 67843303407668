import { MANUFACTURER_ACTIONS } from "../constants/action-types";

const initialState = {
  manufacturers: [],
  message: {},
  status: false,
  saveStatus: false,
};

const manufacturerReducer = (state = initialState, action) => {
  switch (action.type) {
    case MANUFACTURER_ACTIONS.MANUFACTURER_SAVE_START:
      return Object.assign({}, state, {
        saveStatus: false,
      });
    case MANUFACTURER_ACTIONS.MANUFACTURER_SAVE_SUCCESS:
      return Object.assign({}, state, {
        saveStatus: true,
      });
    case MANUFACTURER_ACTIONS.MANUFACTURER_SAVE_ERROR:
      return Object.assign({}, state, {
        saveStatus: false,
        message: action.payload,
      });
    case MANUFACTURER_ACTIONS.MANUFACTURERS_LOADING:
      return Object.assign({}, state, {
        status: false,
        message: {},
        manufacturers: [],
      });
    case MANUFACTURER_ACTIONS.MANUFACTURERS_LOADED:
      return Object.assign({}, state, {
        status: true,
        message: {},
        manufacturers: action.payload.manufacturers,
      });
    case MANUFACTURER_ACTIONS.MANUFACTURERS_ERROR:
      return Object.assign({}, state, {
        status: true,
        message: action.payload.message,
        manufacturers: [],
      });

    default:
      return state;
  }
};

export default manufacturerReducer;
